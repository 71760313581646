import { Modal, Typography } from "antd";

const { Title } = Typography;

interface EnableLoanConfirmationModalProps {
  visible: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const EnableLoanConfirmationModal = ({
  visible,
  onConfirm,
  onCancel,
}: EnableLoanConfirmationModalProps) => {
  return (
    <Modal
      destroyOnClose={true}
      centered
      open={visible}
      title={<Title level={4}>Confirm Changes</Title>}
      width={800}
      okText="Enable Loan"
      onOk={onConfirm}
      onCancel={onCancel}
    >
      <div style={{ textAlign: "center" }}>
        <h3>
          Are you sure you want to enable loan access for this Business Owner?
        </h3>
      </div>
    </Modal>
  );
};

export default EnableLoanConfirmationModal;
