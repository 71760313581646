import React from "react";
import RefedImage from "./RefedImage";

interface ImagesDisplayProps {
    imageRefs: string[];
}

const ImagesDisplay: React.FC<ImagesDisplayProps> = ({ imageRefs }) => {
    return (
        <div className="flex flex-wrap gap-4 mt-4">
            {imageRefs.map((imageRef, index) => (
                <RefedImage
                    key={index}
                    fileRef={imageRef}
                    alt={`Image ${index + 1}`}
                    className="rounded-md object-cover h-20 w-20"
                />
            ))}
        </div>
    );
};

export default ImagesDisplay;
