import { FC } from "react";
import { Descriptions, Typography } from "antd";

interface ActionParameters {
  request: {
    businessOwnerCode: string;
  };
  businessOwnerDetails: {
    businessOwnerName: string;
  };
}

interface Props {
  src: ActionParameters;
}

const EnableLoanViewer: FC<Props> = ({ src }) => {
  return (
    <>
      <Descriptions title="Request Details" layout="vertical">
        <Descriptions.Item
          label={<Typography.Text strong>Business Name</Typography.Text>}
        >
          {src?.businessOwnerDetails?.businessOwnerName || "N/A"}
        </Descriptions.Item>

        <Descriptions.Item
          label={<Typography.Text strong>Business Owner Code</Typography.Text>}
        >
          {src?.request?.businessOwnerCode || "N/A"}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default EnableLoanViewer;
