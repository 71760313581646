import React, { useState } from "react";
import Page, { BreadCrumbItem } from "../../../../../components/Page";
import { DashboardRoutePaths } from "../../../../../routes/Dashboard/routePaths";
import { Button, Card, Col, Form, Input, Row, Select, Table, Tag } from "antd";
import moment from "moment";
import { initPagination } from "../../../../../utils/pagination";
import useQuerySilentVerificationRequests from "../hooks/useQuerySilentVerificationRequests";
import { convertStatusTextToColor } from "../../../../../utils/workflow";
import {
  convertStatusToHumanReadable,
  parseTableSearchParams,
} from "../../../../../utils/dataParser";
import CreateSilentVerificationRequestModal from "../../../../../components/Modals/CreateSilentVerificationRequestModal";
import SilentVerificationDetailsModal from "../../../../../components/Modals/SilentVerificationDetailsModal";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import {
  fetchLGAsByStateCode,
  fetchStates,
  resetLgasByStateCode,
} from "../../../Utils/UtilsSlice/utilsSlice.thunks";

const FormItem = Form.Item;
const { Option } = Select;

const breadCrumbItems: BreadCrumbItem[] = [
  {
    title: "Dashboard",
    path: DashboardRoutePaths.home.path,
  },
  {
    title: "Silent Verification",
    active: true,
  },
];

const SilentVerification = () => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const {
    silentVerificationRequests,
    silentVerificationRequestsPagination,
    getSilentVerificationRequests,
    loading,
  } = useQuerySilentVerificationRequests({
    payload: {
      pagination: initPagination,
    },
  });

  const {
    utils: { states, LGAsByState },
  } = useAppSelector((state) => state);

  const [selectedVerificationReference, setSelectedVerificationReference] =
    useState("");
  const [showRequestDetailsPopUp, setShowRequestDetailsPopUp] = useState(false);
  const [showCreateRequestModal, setShowCreateRequestModal] = useState(false);
  const [searchParams, setSearchParams] = useState({});

  const tableColumns = [
    {
      title: "Business Name",
      dataIndex: "businessName",
      key: "businessName",
      render: (value: string) => <div>{value ? value : "-----"}</div>,
    },
    {
      title: "Address",
      dataIndex: "businessAddress",
      key: "businessAddress",
      render: (value: string) => <div>{value ? value : "-----"}</div>,
    },
    {
      title: "Business Category",
      dataIndex: "businessCategory",
      key: "businessCategory",
      render: (value: string) => <div>{value ? value : "-----"}</div>,
    },
    {
      title: "Verification Status",
      dataIndex: "verificationStatus",
      key: "verificationStatus",
      render: (value: string) => (
        <div>
          {value ? (
            <Tag color={convertStatusTextToColor(value)}>
              {convertStatusToHumanReadable(value)}
            </Tag>
          ) : (
            "-----"
          )}
        </div>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "createdOn",
      key: "createdOn",
      render: (value: string) => (
        <div>{value ? moment(value).format("YYYY-MM-DD") : "-----"}</div>
      ),
    },
    {
      title: "",
      dataIndex: "verificationReference",
      key: "verificationReference",
      render: (value: string) => (
        <Button
          type="primary"
          className="bg-[#0190fe] border-[#0190fe] rounded"
          onClick={() => {
            setSelectedVerificationReference(value);
            setShowRequestDetailsPopUp(true);
          }}
        >
          View Details
        </Button>
      ),
    },
  ];

  const handleTableChange = (pagination: any) => {
    getSilentVerificationRequests(pagination, searchParams);
  };

  const filterSilentVerifications = () => {
    const formValues = form.getFieldsValue();
    const allValues = searchParams
      ? { ...searchParams, ...formValues }
      : formValues;

    let validFields = parseTableSearchParams(allValues);

    setSearchParams(validFields);
    getSilentVerificationRequests(initPagination, validFields);
  };

  const onClearForm = () => {
    form.resetFields();
    setSearchParams({});
    getSilentVerificationRequests(initPagination);
  };

  const onStateChange = (value: string) => {
    if (value) {
      dispatch(fetchLGAsByStateCode(value));
    } else {
      dispatch(resetLgasByStateCode());
      form.setFieldValue("lgaCode", null);
    }
  };

  React.useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      dispatch(fetchStates());
    }
  }, [dispatch]);

  return (
    <Page breadCrumbItems={breadCrumbItems}>
      <div className="flex justify-end">
        <Button type="primary" onClick={() => setShowCreateRequestModal(true)}>
          Create Silent Verification Request
        </Button>
      </div>
      <Card title="Silent Verification Requests Filter" className="my-[18px]" bordered={false}>
        <Form layout="vertical" name="filter-fco" form={form}>
          <Row gutter={24}>
            <Col xs={24} sm={12} md={8}>
              <FormItem
                label="Business Name"
                name={"businessName"}
                initialValue=""
              >
                <Input size="large" placeholder="" />
              </FormItem>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <FormItem label="State" name={"state"} initialValue="">
                <Select
                  size="large"
                  placeholder=""
                  showSearch
                  allowClear={true}
                  onChange={onStateChange}
                >
                  {states.map((state) => (
                    <Option
                      key={state.id}
                      value={state.stateCode}
                      name={state.stateName}
                    >
                      {state.stateName}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <FormItem label="LGA" name={"lga"} initialValue="">
                <Select
                  size="large"
                  placeholder=""
                  showSearch
                  allowClear={true}
                >
                  {LGAsByState.map((lga) => (
                    <Option key={lga.id} value={lga.code} name={lga.name}>
                      {lga.name}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <FormItem
                  label="City"
                  name={"city"}
                  initialValue=""
              >
                <Input size="large" placeholder="" />
              </FormItem>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <FormItem
                label="Business Category"
                name={"businessCategory"}
                initialValue=""
              >
                <Input size="large" placeholder="" />
              </FormItem>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <FormItem
                label="Verification Status"
                name={"verificationStatus"}
                initialValue=""
              >
                <Select size="large" placeholder="">
                  <Select.Option value="completed">Completed</Select.Option>
                  <Select.Option value="pending">Pending</Select.Option>
                  <Select.Option value="cancelled">Cancelled</Select.Option>
                </Select>
              </FormItem>
            </Col>
          </Row>

          <Row gutter={[12, 12]}>
            <Col xs={24} sm={12} md={3}>
              <Button
                type="primary"
                className="w-full rounded"
                danger
                onClick={onClearForm}
              >
                Clear
              </Button>
            </Col>
            <Col xs={24} sm={12} md={4}>
              <Button
                type="primary"
                className="w-full bg-[#0190fe] border-[#0190fe] rounded"
                htmlType="submit"
                onClick={filterSilentVerifications}
              >
                Search
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
      <Card title="Silent Verification Requests" className="my-[18px] w-full">
        <Table
          className="w-full overflow-x-auto"
          dataSource={silentVerificationRequests}
          columns={tableColumns}
          rowKey={(record) => record.verificationReference}
          loading={loading}
          pagination={silentVerificationRequestsPagination}
          onChange={handleTableChange}
        />
      </Card>
      {showCreateRequestModal && (
        <CreateSilentVerificationRequestModal
          onClose={() => {
            setShowCreateRequestModal(false);
            getSilentVerificationRequests(initPagination);
          }}
        />
      )}
      {showRequestDetailsPopUp && (
        <SilentVerificationDetailsModal
          onClose={() => setShowRequestDetailsPopUp(false)}
          verificationReference={selectedVerificationReference}
        />
      )}
    </Page>
  );
};

export default SilentVerification;
