import React, {useState} from "react";
import {get_pending_termination_request} from "../API";
import {showServerError} from "../../../../utils/notifications";

interface Params {
    ref: string;
}

const useQueryPendingTerminationRequest = ({ ref }: Params) => {
    const [isLoadingPendingTerminationRequest, setIsLoadingPendingTerminationRequest] =
        React.useState(false);
    const [hasPendingTerminationRequest, setHasPendingTerminationRequest] = useState(false);

    const checkPendingTerminationRequest = async () => {
        try {
            setIsLoadingPendingTerminationRequest(true);
            const response = await get_pending_termination_request(ref);
            setHasPendingTerminationRequest(response.hasPendingRequest);
        } catch (error: any) {
            showServerError(error);
        } finally {
            setIsLoadingPendingTerminationRequest(false);
        }
    };

    React.useEffect(() => {
        checkPendingTerminationRequest();
    }, [ref]);

    return {
        isLoadingPendingTerminationRequest,
        hasPendingTerminationRequest,
        checkPendingTerminationRequest,
    };
};

export default useQueryPendingTerminationRequest;
