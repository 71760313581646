import { Descriptions, Typography } from "antd";
import { ILoanManagers } from "../../utils/types";
import LoanManager from "./LoanManager";

const { Text } = Typography;

type LoanManagersProps = {
  details?: ILoanManagers | null;
};

const LoanManagers = ({ details }: LoanManagersProps) => {
  return (
    <div className="mt-4">
      <div className="font-semibold text-base">Loan Managers</div>
      <div className="managers mt-4">
        {details?.fcos?.map((fco, index) => (
          <LoanManager
            key={fco.code} // Use a unique identifier
            loanManager={fco}
            managerRole={`Field Credit Officer ${index + 1}`} // Dynamically label each FCO
          />
        ))}
        {details?.pm?.name && (
          <LoanManager
            loanManager={details.pm}
            managerRole="Portfolio Manager"
          />
        )}
        {details?.rm?.name && (
          <LoanManager
            loanManager={details.rm}
            managerRole="Relationship Manager"
          />
        )}
      </div>
    </div>
  );
};

export default LoanManagers;
