import React from "react";
import { showMessage, showServerError } from "../../../../utils/notifications";
import { update_guarantor_details } from "../API";

interface ResidentialAddress {
  line1: string;
  line2: string;
  city: string;
  lgaCode: string;
}

interface UpdateRequest {
  email: string;
  phoneNumber: string;
  residentialAddress: ResidentialAddress;
}

export interface UpdateGuarantorParams {
  loanRequestReference: string;
  bvn: string;
  updateRequest: UpdateRequest;
  authorizationProof: string;
}

interface Params {
  payload: UpdateGuarantorParams;
  callback: () => void;
}

const useUpdateGuarantorDetails = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const updateGuarantorDetails = async ({
    payload,
    callback,
  }: Params): Promise<void> => {
    try {
      setIsLoading(true);

      const response = await update_guarantor_details(payload); // Ensure this function handles the PUT request

      if (response) {
        callback();
        showMessage("Successful", "Request has been sent for approval.");
      }
    } catch (error) {
      showServerError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    updateGuarantorDetails,
  };
};

export default useUpdateGuarantorDetails;
