import { ArrowRightOutlined } from "@ant-design/icons";
import { Input, Select } from "antd";
import { parseAmountToCurrencyValue } from "../../../../../../utils/dataParser";

interface CustomDescriptionItemProps {
  label?: React.ReactNode;
  value: any;
  editMode: boolean;
  setValue: (value: any) => void;
  parseAmount?: boolean;
  renderDescription?: boolean;
  dateTenure?: boolean;
  editProps?: any;
  dateProps?: {
    disallowHourly?: boolean;
    disallowWeekly?: boolean;
    disallowMonthly?: boolean;
    disallowDaily?: boolean;
  };
  valueUpdated?: string | number;
  renderProps?: {
    suffix?: string;
    suffixForUpdatedValue?: string;
    prefix?: string;
    prefixForUpdatedValue?: string;
  };
  inputSuffix?: string;
  direction?: 'vertical' | 'horizontal'
}

const { Option } = Select;

const CustomDescriptionItem: React.FC<CustomDescriptionItemProps> = ({
  label,
  value,
  editMode,
  setValue,
  parseAmount = false,
  renderDescription = true,
  dateTenure = false,
  editProps,
  valueUpdated,
  dateProps = {
    disallowHourly: true,
    disallowWeekly: true,
    disallowMonthly: true,
    disallowDaily: true,
  },
  renderProps,
    inputSuffix,
    direction
}) => {
  // Function to handle value change
  const handleChange = (newValue: string) => {
    setValue(newValue);
  };

  // Function to render the updated value
  const renderUpdatedValue = () => {
    return (
        <div className={`flex ${direction === 'vertical' ? "flex-col items-start" : "items-center"}`}>
          <span style={{ color: "red", textAlign: "left" }}>{formatValue(value)}</span>
          <ArrowRightOutlined
              className={`ml-3 mr-3 ${direction === 'vertical' ? "my-4 flex-none transform rotate-90" : ""}`}
          />
          <span style={{ color: "green", textAlign: "left" }}>
                {formatValue(valueUpdated || "", true)}
            </span>
        </div>
    );
  };

  // Function to format the value
  const formatValue = (valueToFormat: string | number, updated?: boolean) => {
    if (parseAmount && !editMode) {
      return parseAmountToCurrencyValue(valueToFormat, "₦");
    }
    const valueToRender = renderProps?.prefix
      ? renderProps?.prefix + " " + valueToFormat
      : valueToFormat;
    if (renderProps && !updated)
      return valueToRender + "" + renderProps?.suffix;
    if (renderProps && renderProps.suffixForUpdatedValue)
      return valueToRender + "" + renderProps?.suffixForUpdatedValue;

    return valueToFormat;
  };

  // Function to render the value
  const renderValue = () => {
    if (valueUpdated) return renderUpdatedValue();
    if (parseAmount && !editMode) {
      return parseAmountToCurrencyValue(value, "₦");
    }
    return formatValue(value);
  };

  // If renderDescription is false, return null
  if (!renderDescription) return null;

  return (
    <>
      {editMode ? (
        // Render input or select based on dateTenure
        dateTenure ? (
          <Select value={value} onChange={handleChange}
          >
            {!dateProps.disallowHourly && <Option value="HOURLY">HOURLY</Option>}
            {!dateProps.disallowDaily && <Option value="DAILY">DAILY</Option>}
            {!dateProps.disallowWeekly && <Option value="WEEKLY">WEEKLY</Option>}
            {!dateProps.disallowMonthly && <Option value="MONTHLY">MONTHLY</Option>}
          </Select>
        ) : (
          <Input
            value={value}
            onChange={(e) => handleChange(e.target.value)}
            {...editProps}
              suffix={inputSuffix}
          />
        )
      ) : (
        renderValue() // Render value if not in edit mode
      )}
    </>
  );
};

export default CustomDescriptionItem;
