import { InterestWaivePeriod } from "../utils/types";
import React from "react";
import { get_interest_waive_periods } from "../API";
import { showServerError } from "../../../../utils/notifications";

interface Params {
  ref: string;
}

const useQueryInterestWaivePeriods = ({ ref }: Params) => {
  const [isLoadingWaivePeriods, setIsLoadingWaivePeriods] =
    React.useState<boolean>(false);
  const [interestWavePeriods, setInterestWavePeriods] = React.useState<
    InterestWaivePeriod[] | null
  >(null);
  const [waivePeriodsPermissionError, setWaivePeriodsPermissionError] =
    React.useState<boolean | null>(false);

  const fetchInterestWavePeriods = async (ref: string) => {
    try {
      setIsLoadingWaivePeriods(true);
      const response = await get_interest_waive_periods(ref);
      setInterestWavePeriods(response);
    } catch (error: any) {
      if (error.response && error.response.status === 403) {
        setWaivePeriodsPermissionError(true);
        return; //exit early
      }
      showServerError(error);
    } finally {
      setIsLoadingWaivePeriods(false);
    }
  };

  React.useEffect(() => {
    fetchInterestWavePeriods(ref);
  }, [ref]);

  return {
    isLoadingWaivePeriods,
    waivePeriodsPermissionError,
    interestWavePeriods,
  };
};

export default useQueryInterestWaivePeriods;
