import { Feature } from "./feature-config";
import { TConfig } from "./type";

const CONFIG_PRODUCTION: TConfig = {
  VERSION: "0.0.1",
  BASE_URL: "https://wcl-user-management.moniepoint.com/api/v1",
  LOCATION_BASE_URL: "https://location-v2.api.kuwego.mp.monnify.com/api/v1",
  WCL_PORTFOLIO_BASE_URL:
    "https://wcl-portfolio-backoffice-service.moniepoint.com/api/v1",
  WCL_LOANS_BO_BASE_URL: "https://wcl-loans-bo-service.moniepoint.com/api/v1",
  cosmos_base_url: "https://cosmos.console.teamapt.com",
  client_id: "wcl-user-management-client",
  auth_domain: "MONNIFY",
  WCL_MANAGEMENT_BASE_URL: "",
  excluded_features: [
    Feature.LOAN_PROGRAM_MANAGEMENT,
    Feature.LOCALGOVERNMENT_ACTIVATION,
  ],
};

export default CONFIG_PRODUCTION;
