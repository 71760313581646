import React, { useState } from "react";
import { get_pending_approval_request } from "../API";
import { showServerError } from "../../../../utils/notifications";

interface Params {
  ref: string;
}

const useQueryPendingApprovalRequest = ({ ref }: Params) => {
  const [isLoadingPendingApprovalRequest, setIsLoadingPendingApprovalRequest] =
    React.useState(false);
  const [hasPendingRequest, setHasPendingRequest] = useState(false);

  const checkPendingApprovalRequest = async () => {
    try {
      setIsLoadingPendingApprovalRequest(true);
      const response = await get_pending_approval_request(ref);
      setHasPendingRequest(response.hasPendingRequest);
    } catch (error: any) {
      showServerError(error);
    } finally {
      setIsLoadingPendingApprovalRequest(false);
    }
  };

  React.useEffect(() => {
    checkPendingApprovalRequest();
  }, [ref]);

  return {
    isLoadingPendingApprovalRequest,
    hasPendingRequest,
    checkPendingApprovalRequest,
  };
};

export default useQueryPendingApprovalRequest;
