import React from "react";
import { Card, Spin, Steps } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import {
  ILoanApprovalStatusDetails,
  ILoanRequestApprovalDetails,
} from "../../utils/types";

const { Step } = Steps;

type LoanStatusTrackerProps = {
  details?: ILoanRequestApprovalDetails;
  isLoadingDetails: boolean;
};

function LoanStatusTracker({
  details,
  isLoadingDetails,
}: LoanStatusTrackerProps) {
  const currentStatus = determineCurrentStatus(details?.loanRequestStatus);

  const steps = [
    {
      title: <small>LOAN APPLICATION</small>,
    },
    {
      title: (
        <small style={{ marginLeft: "-35px" }}>
          FIELD VERIFICATION & LOAN AGREEMENTS
        </small>
      ),
      content: [
        ...(details?.loanAgreements ?? []),
        ...(details?.fieldVerifications ?? []),
      ],
    },
    {
      title: (
        <small style={{ marginLeft: "-15px" }}> INTERNAL APPROVALS </small>
      ),
    },
  ];

  return (
    <Card>
      {isLoadingDetails ? (
        <section className="flex items-center justify-center">
          <Spin tip="Loading Approval Details..." />
        </section>
      ) : (
        <Steps current={currentStatus} size="small" labelPlacement="vertical">
          {steps.map((step, index) => (
            <Step
              key={index}
              title={step.title}
              description={
                index === 1 ? renderDetails(step.content ?? []) : null
              }
            />
          ))}
        </Steps>
      )}
    </Card>
  );
}

function renderDetails(details: ILoanApprovalStatusDetails[]) {
  return details.map((item, index: React.Key | null | undefined) => (
    <div key={index} style={{ display: "flex", alignItems: "center" }}>
      {getStatusIcon(item?.status || "")}
      <span style={{ marginLeft: 8 }}>{item?.type || ""}</span>
    </div>
  ));
}

function getStatusIcon(status: string) {
  switch (status) {
    case "APPROVED":
      return <CheckCircleOutlined style={{ color: "green" }} />;
    case "REJECTED":
      return <CloseCircleOutlined style={{ color: "red" }} />;
    case "PENDING":
      return <ClockCircleOutlined style={{ color: "orange" }} />;
    default:
      return null;
  }
}

const LOAN_APPLICATION_STEP = 0;
const FIELD_VERIFICATION_STEP = 1;
const INTERNAL_APPROVALS_STEP = 2;

function determineCurrentStatus(status?: "PENDING" | "ACCEPTED" | "REJECTED") {
  switch (status) {
    case "PENDING":
      return LOAN_APPLICATION_STEP;
    case "ACCEPTED":
      return FIELD_VERIFICATION_STEP;
    case "REJECTED":
      return INTERNAL_APPROVALS_STEP;
    default:
      return LOAN_APPLICATION_STEP; // Default to the first step if status is undefined
  }
}

export default LoanStatusTracker;
