export type UsersTypes = {
  name: string;
  email: string;
  userName: string;
  activation: string;
  status: string;
};
export enum UsersTableStatus {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
}
export enum UsersTableActivation {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}
