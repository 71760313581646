import { useState } from "react";

export const useLoadingAndErrorState = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [permissionError, setPermissionError] = useState<boolean | null>(false);

  const setLoadingError = (hasError: boolean, errorMsg = "") => {
    setError(hasError ? errorMsg : "");
    setLoading(false);
  };

  return {
    loading,
    setLoading,
    error,
    setError,
    permissionError,
    setPermissionError,
    setLoadingError,
  };
};
