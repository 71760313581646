import { Spin } from "antd";
import { ILoanRequestDetails } from "../../utils/types";
import LoanManagers from "./LoanManagers";
import MoniepointManagers from "./MoniepointManagers";
import useQueryLoanRequest from "../../hooks/useQueryLoanRequest";
import { useLocation, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { LoanRequestTabEnum } from "../../../loanRequestSlice/loanRequest.types";

type LoanParticipantsProps = {
  details?: ILoanRequestDetails;
  loanRequestReference: string | null;
};

const LoanParticipants = ({
  details,
  loanRequestReference,
}: LoanParticipantsProps) => {
  const location = useLocation();
  const { loanRequestStatusTab } = location.state || {};

  const shouldGetLoanManagers = [
    LoanRequestTabEnum.APPROVED,
    LoanRequestTabEnum.PENDING_APPROVAL,
    LoanRequestTabEnum.REJECTED,
  ].includes(loanRequestStatusTab);
  const { loading, getLoanManagers, loanManagers } = useQueryLoanRequest({
    loanReference: loanRequestReference,
  });

  useEffect(() => {
    if (shouldGetLoanManagers) {
      getLoanManagers();
    }
  }, [loanRequestStatusTab]);

  return (
    <div>
      {loading ? (
        <Spin />
      ) : (
        <>
          <MoniepointManagers details={details} />
          <br />
          {shouldGetLoanManagers && (
            <>
              <LoanManagers details={loanManagers} />
              <br />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default LoanParticipants;
