import { useCallback, useState } from "react";
import { wcl_to_ovd_conversion_eligibility_service } from "../API";
import { useLoadingAndErrorState } from "./useLoadingAndErrorState";
import { showMessage } from "../../../../../utils/notifications";
import { parseError } from "../../../../../utils/server";
import { WCLConversionEligibility } from "../utils/types";

export const useLoanConversionEligibility = () => {
  const { loading, setLoading, setLoadingError, setPermissionError } =
    useLoadingAndErrorState();
  const [conversionEligibility, setConversionEligibility] =
    useState<WCLConversionEligibility | null>(null);

  const getLoanConversionEligibility = useCallback(
    async (loanReference: string) => {
      try {
        setLoading(true);
        setLoadingError(false);
        const response =
          await wcl_to_ovd_conversion_eligibility_service(loanReference);
        setConversionEligibility(response);
      } catch (error: any) {
        if (error.response && error.response.status === 403) {
          setPermissionError(true);
          return;
        }
        showMessage("Error", parseError(error), "error");
        setLoadingError(true, parseError(error));
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  return { getLoanConversionEligibility, loading, conversionEligibility };
};
