import { Tabs } from "antd";
import React from "react";
import {
  LoanRequestTabEnum,
  loanRequestTabEnumString,
} from "../../loanRequestSlice/loanRequest.types";
import { useQueryAllLoanRequests } from "../hooks/useQueryLoanRequests";
import LoanOfferErrorDetails from "./LoanOfferErrorDetails";
import ApprovedLoanRequests from "./tabs/ApprovedLoanRequests";
import PendingLoanRequests from "./tabs/PendingLoanRequests";
import PendingApprovalLoanRequests from "./tabs/PendingApprovalLoanRequests";
import RejectedLoanRequests from "./tabs/RejectedLoanRequests";
import TerminatedLoanRequests from "./tabs/TerminatedLoanRequests";
import { showError } from "../../../../../utils/notifications";
import { useSearchParams } from "react-router-dom";

export interface Rule {
  required?: boolean;
  digitsOnly?: boolean;
  length?: number;
  greaterThanZero?: boolean;
  notLessThanZero?: boolean;
  notGreaterThanHundred?: boolean;
  decimalOnly?: boolean;
}

export interface Rules {
  [key: string]: Rule;
}

const tabComponents = [
  {
    tab: LoanRequestTabEnum.AWAITING_SUBMISSION,
    component: PendingLoanRequests,
  },
  {
    tab: LoanRequestTabEnum.AWAITING_OFFER,
    component: PendingLoanRequests,
  },
  {
    tab: LoanRequestTabEnum.PENDING_OFFER,
    component: PendingLoanRequests,
  },
  {
    tab: LoanRequestTabEnum.PENDING_APPROVAL,
    component: PendingApprovalLoanRequests,
  },
  { tab: LoanRequestTabEnum.APPROVED, component: ApprovedLoanRequests },
  { tab: LoanRequestTabEnum.REJECTED, component: RejectedLoanRequests },
  { tab: LoanRequestTabEnum.TERMINATED, component: TerminatedLoanRequests },
];

export const validateLoanRequestFilter = (
  filterParams: any,
  rules: Rules,
  labels: { [key: string]: string },
) => {
  for (const key in filterParams) {
    if (rules.hasOwnProperty(key)) {
      const value = filterParams[key];
      const rule = rules[key];
      const label = labels[key];

      if (rule.required && !value && value !== 0) {
        return `${label} is required.`;
      } else if (rule.digitsOnly && !/^\d+$/.test(value)) {
        return `${label} must contain only digits.`;
      } else if (rule.length && value.length !== rule.length) {
        return `${label} must be ${rule.length} characters long.`;
      } else if (rule.greaterThanZero && parseFloat(value) <= 0) {
        // Check if value is less than or equal to zero
        return `${label} must be greater than zero.`;
      } else if (rule.notLessThanZero && parseFloat(value) < 0) {
        // Check if value is less than zero
        return `${label} must be greater than zero.`;
      } else if (rule.notGreaterThanHundred && parseFloat(value) > 100) {
        return `${label} cannot be greater than 100.`;
      }else if (rule.decimalOnly && !/^\d+(\.\d+)?$/.test(value)) {
        return `${label} must be a valid decimal number.`;
      }
    }
  }

  return null;
};
const LoanRequestTabs = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultLoanRequestTab =
    searchParams.get("requestTab") || LoanRequestTabEnum.AWAITING_SUBMISSION;
  const [activeTab, setActiveTab] = React.useState(defaultLoanRequestTab);
  const api = useQueryAllLoanRequests({
    payload: {
      status: activeTab,
    },
  });
  const {
    error,
    loading,
    loanRequests,
    loanRequestsPagination,
    getAllLoanRequests,
    permissionError,
  } = api;
  const rules: Rules = {
    businessOwnerName: { required: true },
    businessName: { required: true },
    bvn: { digitsOnly: true, length: 11 },
    accountNumber: { digitsOnly: true, length: 10 },
  };
  const labels: { [key: string]: string } = {
    businessOwnerName: "Business Owner Name",
    businessName: "Business Name",
    bvn: "BVN",
    accountNumber: "Account Number",
  };
  const fetchData = (
    status: string,
    searchParams?: Record<string, any>,
    pagination?: any,
  ) => {
    // validate values in search params
    const error = validateLoanRequestFilter(searchParams, rules, labels);
    if (error) {
      showError(error);
    }
    return getAllLoanRequests(status, searchParams, pagination);
  };

  const getTabComponent = (tab: string) => {
    const componentObj = tabComponents.find((item) => item.tab === tab);
    return componentObj ? (
      <componentObj.component
        loanRequests={loanRequests}
        loading={loading}
        status={tab}
        error={error}
        pagination={loanRequestsPagination}
        fetchData={fetchData}
      />
    ) : null;
  };
  const tabItemsList = Object.values(LoanRequestTabEnum).map((tab, index) => ({
    key: `${index + 1}`,
    label: loanRequestTabEnumString[tab],
    labelIndex: tab,
    children: getTabComponent(tab),
  }));
  const onChange = (key: string) => {
    const tab = tabItemsList.find((item) => item.key === key);
    setSearchParams({ requestTab: tab?.labelIndex || "" });
    setActiveTab(tab?.labelIndex || LoanRequestTabEnum.AWAITING_SUBMISSION);
    getAllLoanRequests(
      tab?.labelIndex || LoanRequestTabEnum.AWAITING_SUBMISSION,
    );
  };
  const defaultActiveKey = tabItemsList.find(
    (item) => item.labelIndex === activeTab,
  )?.key;
  const permissionErrorText = "You do not have permission to view this page.";
  return permissionError ? (
    <LoanOfferErrorDetails
      isPermissionError={permissionError}
      errorMessage={permissionErrorText}
      backProps={{
        text: "Go Back",
        show: true,
      }}
    />
  ) : (
    <Tabs
      defaultActiveKey={defaultActiveKey}
      items={tabItemsList}
      onChange={onChange}
    />
  );
};
export default LoanRequestTabs;
