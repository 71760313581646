import { parseError } from "../../../../../utils/server";
import { showMessage } from "../../../../../utils/notifications";
import { deactivate_pm } from "../../portfolioApi";

export const deactivatePM = async (pmCode: string) => {
    try {
        const response = await deactivate_pm(pmCode);
        if (response?.requestSuccessful) {
            showMessage(
                "Request Successful!", 
                "Successfuly sent request to deactivate PM", 
                'success'
            );
        }
    } catch (error) {
        showMessage("Request Failed!", parseError(error), "error");
    }
}
