import { useState, useEffect } from "react";
import { showServerError } from "../../../../utils/notifications";
import { api_fetch_guarantor_eligibility } from "../API";
import {LoanRequestTabEnum} from "../../LoanRequestsManagement/loanRequestSlice/loanRequest.types";

export interface GuarantorEligibilityStatus {
  bvn: string;
  eligibleToUpdate: boolean;
  hasPendingUpdateRequest: boolean;
  verificationStatus: "PENDING" | "COMPLETED" | "FAILED";
}

const useGuarantorEligibility = (loanReqRef: string, bvns: string[], loanRequestStatusTab: string) => {

  const isEligibleForUpdate =
      loanRequestStatusTab === LoanRequestTabEnum.APPROVED ||
      loanRequestStatusTab === LoanRequestTabEnum.PENDING_APPROVAL ||
      loanRequestStatusTab === LoanRequestTabEnum.REJECTED;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [eligibilityStatuses, setEligibilityStatuses] = useState<
      GuarantorEligibilityStatus[]
  >([]);

  const fetchEligibilityStatuses = async () => {
    try {
      setIsLoading(true);

      // Fetch eligibility for each BVN in parallel
      const results = await Promise.all(
          bvns.map(async (bvn) => {
            const response = await api_fetch_guarantor_eligibility({
              loanReqRef,
              bvn,
            });
            return { bvn, ...response };
          }),
      );

      setEligibilityStatuses(results);
    } catch (error) {
      showServerError(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (isEligibleForUpdate && loanReqRef && bvns.length > 0) {
      fetchEligibilityStatuses();
    }
  }, [loanReqRef, bvns]);

  return { isLoading, eligibilityStatuses, fetchEligibilityStatuses};
};

export default useGuarantorEligibility;
