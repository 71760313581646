import {
  Modal,
  Typography,
  Spin,
  Tag,
  Descriptions,
  Button,
  Collapse,
} from "antd";
import React, { useState } from "react";
import useQuerySilentVerificationRequestDetails from "../../modules/Dashboard/LoanContractsManagement/hooks/useQuerySilentVerificationRequestDetails";
import { convertStatusTextToColor } from "../../utils/workflow";
import { convertStatusToHumanReadable } from "../../utils/dataParser";
import ImagesDisplay from "../ImagesDisplay";
import moment from "moment";

const { Title, Text } = Typography;
const { Panel } = Collapse;

interface SilentVerificationDetailsModalProps {
  onClose: () => void;
  verificationReference?: string;
}

const SilentVerificationDetailsModal: React.FC<
  SilentVerificationDetailsModalProps
> = ({ onClose, verificationReference }) => {
  const { requestDetails, isLoadingRequestDetails } =
    useQuerySilentVerificationRequestDetails({
      ref: verificationReference ?? "",
    });

  const [activeKey, setActiveKey] = useState<string | string[]>("1");

  if (isLoadingRequestDetails) {
    return (
      <Modal
        destroyOnClose={true}
        centered
        open={true}
        title={<Title level={4}>Silent Verification Request Details</Title>}
        width={800}
        onCancel={onClose}
        footer={null}
      >
        <div className="flex items-center justify-center h-full">
          <Spin tip="Loading request details..." />
        </div>
      </Modal>
    );
  }

  if (!requestDetails) {
    return null;
  }

  const {
    businessName,
    businessCategory,
    requestParameters,
    responseParameters,
    verificationStatus,
  } = requestDetails;

  const parsedRequestParameters = requestParameters
    ? JSON.parse(String(requestParameters))
    : {};
  const parsedResponseParameters = responseParameters
    ? JSON.parse(String(responseParameters))
    : {};
  const parsedMetadata = parsedRequestParameters?.metadata
    ? JSON.parse(parsedRequestParameters.metadata)
    : {};
  const parsedVerificationData = parsedResponseParameters?.verificationData
    ? JSON.parse(parsedResponseParameters.verificationData)
    : {};

  return (
    <Modal
      destroyOnClose={true}
      centered
      open={true}
      title={
        <Title level={4} className="flex items-center">
          Silent Verification Request Details -{" "}
          <Tag
            className="mx-4"
            color={convertStatusTextToColor(verificationStatus)}
          >
            {convertStatusToHumanReadable(verificationStatus)}
          </Tag>
        </Title>
      }
      width={800}
      onCancel={onClose}
      footer={[
        <Button key="back" type="primary" onClick={onClose}>
          Ok
        </Button>,
      ]}
    >
      <div className="p-4 space-y-6">
        <Collapse accordion activeKey={activeKey} onChange={setActiveKey}>
          <Panel header="Request Details" key="1">
            <Descriptions layout="vertical" column={2}>
              <Descriptions.Item label={<Text strong>Business Name</Text>}>
                {businessName}
              </Descriptions.Item>
              <Descriptions.Item label={<Text strong>Business Category</Text>}>
                {businessCategory}
              </Descriptions.Item>
              {parsedRequestParameters?.verificationAddress && (
                <Descriptions.Item
                  label={<Text strong> Verification Address</Text>}
                >
                  {`${parsedRequestParameters?.verificationAddress?.addressLineOne}, 
                  ${parsedRequestParameters?.verificationAddress?.addressLineTwo}, 
                  ${parsedRequestParameters?.verificationAddress?.city}, 
                  ${parsedRequestParameters?.verificationAddress?.lga}`}
                </Descriptions.Item>
              )}
              {parsedRequestParameters?.verificationReference && (
                <Descriptions.Item
                  label={<Text strong>Request Reference</Text>}
                >
                  {parsedRequestParameters?.verificationReference}
                </Descriptions.Item>
              )}
              {parsedRequestParameters?.assignee && (
                <Descriptions.Item label={<Text strong>Assignee</Text>}>
                  {parsedRequestParameters?.assignee}
                </Descriptions.Item>
              )}
              {parsedRequestParameters?.verificationType && (
                <Descriptions.Item
                  label={<Text strong>Verification Type</Text>}
                >
                  {parsedRequestParameters?.verificationType}
                </Descriptions.Item>
              )}
              {parsedMetadata?.businessImages?.length > 0 && (
                <Descriptions.Item label={<Text strong> Business Images</Text>}>
                  <ImagesDisplay imageRefs={parsedMetadata.businessImages} />
                </Descriptions.Item>
              )}
            </Descriptions>
          </Panel>
          {responseParameters && (
            <Panel header="Response Details" key="2">
              <Descriptions layout="vertical" column={2}>
                {verificationStatus === "COMPLETED" && (
                  <>
                    <Descriptions.Item
                      label={<Text strong>Verification Reference</Text>}
                    >
                      {
                        parsedResponseParameters?.verificationRequest
                          ?.verificationReference
                      }
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={<Text strong>Verification Result</Text>}
                    >
                      <Tag
                        className="mx-4"
                        color={convertStatusTextToColor(
                          parsedResponseParameters?.verificationResult,
                        )}
                      >
                        {convertStatusToHumanReadable(
                          parsedResponseParameters?.verificationResult,
                        )}
                      </Tag>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={<Text strong>Verification Status</Text>}
                    >
                      <Tag
                        className="mx-4"
                        color={convertStatusTextToColor(
                          parsedResponseParameters?.verificationStatus,
                        )}
                      >
                        {convertStatusToHumanReadable(
                          parsedResponseParameters?.verificationStatus,
                        )}
                      </Tag>
                    </Descriptions.Item>
                    <Descriptions.Item
                      label={<Text strong>Verification Date</Text>}
                    >
                      {moment(
                        parsedResponseParameters?.verificationDate,
                      ).format("DD-MM-YYYY")}
                    </Descriptions.Item>
                  </>
                )}
              </Descriptions>
            </Panel>
          )}
          { verificationStatus === "PENDING" ?
              null
              :
            <Panel header="Verification Data" key="3">
              <Descriptions layout="vertical" column={2}>
                <Descriptions.Item label={<Text strong>Can Find Address</Text>}>
                  {parsedVerificationData?.canFindAddress ? "Yes" : "No"}
                </Descriptions.Item>
                <Descriptions.Item
                  label={<Text strong>Location Coordinates</Text>}
                >
                  Latitude:{" "}
                  {parsedVerificationData?.verificationLocation?.latitude},
                  Longitude:{" "}
                  {parsedVerificationData?.verificationLocation?.longitude}
                </Descriptions.Item>
                <Descriptions.Item
                  label={<Text strong>Business Structure</Text>}
                >
                  {parsedVerificationData?.businessStructure}
                </Descriptions.Item>
                {parsedVerificationData?.fvoLivenessCheck && (
                  <Descriptions.Item
                    label={<Text strong>FVO Liveness Check</Text>}
                  >
                    {parsedVerificationData.fvoLivenessCheck}
                  </Descriptions.Item>
                )}
                {parsedVerificationData?.isBusinessAtAddress && (
                  <Descriptions.Item
                    label={<Text strong>Is Business at Address</Text>}
                  >
                    {parsedVerificationData.isBusinessAtAddress ? "Yes" : "No"}
                  </Descriptions.Item>
                )}
                {parsedVerificationData?.isBusinessCategoryCorrect && (
                  <Descriptions.Item
                    label={<Text strong>Is Business Category Correct</Text>}
                  >
                    {parsedVerificationData.isBusinessCategoryCorrect
                      ? "Yes"
                      : "No"}
                  </Descriptions.Item>
                )}
                {parsedVerificationData?.businessHasSignage !== null && (
                  <Descriptions.Item
                    label={<Text strong>Business Has Signage</Text>}
                  >
                    {parsedVerificationData.businessHasSignage ? "Yes" : "No"}
                  </Descriptions.Item>
                )}
                {parsedVerificationData?.addressImages?.length > 0 && (
                  <Descriptions.Item
                    label={<Text strong>Address Verification Images</Text>}
                  >
                    <ImagesDisplay
                      imageRefs={parsedVerificationData.addressImages.map(
                        (img: any) => img.reference,
                      )}
                    />
                  </Descriptions.Item>
                )}
                {parsedVerificationData?.businessAtAddressImages?.length >
                  0 && (
                  <Descriptions.Item
                    label={<Text strong>Business At Address Images</Text>}
                  >
                    <ImagesDisplay
                      imageRefs={parsedVerificationData.businessAtAddressImages.map(
                        (img: any) => img.reference,
                      )}
                    />
                  </Descriptions.Item>
                )}
                {parsedVerificationData?.businessCategoryImages?.length > 0 && (
                  <Descriptions.Item
                    label={<Text strong>Business Category Images</Text>}
                  >
                    <ImagesDisplay
                      imageRefs={parsedVerificationData.businessCategoryImages.map(
                        (img: any) => img.reference,
                      )}
                    />
                  </Descriptions.Item>
                )}
                {parsedVerificationData?.businessIntoCICOImages?.length > 0 && (
                  <Descriptions.Item
                    label={<Text strong>Business Into CICO Images</Text>}
                  >
                    <ImagesDisplay
                      imageRefs={parsedVerificationData.businessIntoCICOImages.map(
                        (img: any) => img.reference,
                      )}
                    />
                  </Descriptions.Item>
                )}
                {parsedVerificationData?.businessStructureImages?.length >
                  0 && (
                  <Descriptions.Item
                    label={<Text strong>Business Structure Images</Text>}
                  >
                    <ImagesDisplay
                      imageRefs={parsedVerificationData.businessStructureImages.map(
                        (img: any) => img.reference,
                      )}
                    />
                  </Descriptions.Item>
                )}
              </Descriptions>
            </Panel>
          }
        </Collapse>
      </div>
    </Modal>
  );
};

export default SilentVerificationDetailsModal;
