import React from "react";
import { terminate_interest_waiver } from "../API";
import { showMessage, showServerError } from "../../../../utils/notifications";

interface Params {
  payload: {
    contractRef: string;
    waivePeriodId: string;
    comment: string;
  };
  callback: () => void;
}

const useTerminateWaivePeriod = () => {
  const [isLoading, setIsLoading] = React.useState(false);

  const terminateWaivePeriod = async ({ payload, callback }: Params) => {
    try {
      setIsLoading(true);

      const response = await terminate_interest_waiver(payload);

      if (response) {
        callback();
        showMessage("Successful", "Request has been sent for approval.");
      }
    } catch (error) {
      showServerError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    terminateWaivePeriod,
  };
};

export default useTerminateWaivePeriod;
