import { Button, Card, Col, Form, Input, Row, Table } from "antd";
import moment from "moment";
import { useState } from "react";
import { Link } from "react-router-dom";
import {
  parseAmountToCurrencyValue,
  parseTableSearchParams,
} from "../../../../../../utils/dataParser";
import { initPagination } from "../../../../../../utils/pagination";
import LoanProductTag from "../loanDetails/LoanProductTag";
import { ILoanRequestTabProp } from "./PendingAcceptanceLoanRequests";
const FormItem = Form.Item;

const PendingApprovalLoanRequests = (prop: ILoanRequestTabProp) => {
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useState({});
  const { loanRequests, loading, pagination, fetchData, status } = prop;
  const tableColumns = [
    {
      title: "Business Name",
      dataIndex: "businessName",
      key: "businessName",
      render: (value: string) => {
        return <div>{value ? value : "-----"}</div>;
      },
    },
    {
      title: "Business Owner Name",
      dataIndex: "businessOwnerName",
      key: "businessOwnerName",
      render: (value: string) => {
        return <div>{value ? value : "-----"}</div>;
      },
    },
    {
      title: "Amount Requested",
      dataIndex: "amountRequested",
      key: "amountRequested",
      render: (value: string) => {
        return (
          <div>
            {value ? parseAmountToCurrencyValue(value, "₦") : "UNAVAILABLE"}
          </div>
        );
      },
    },
    {
      title: "Offer Amount",
      dataIndex: "offerAmount",
      key: "offerAmount",
      render: (value: string) => {
        return (
          <div>
            {value ? parseAmountToCurrencyValue(value, "₦") : "UNAVAILABLE"}
          </div>
        );
      },
    },
    {
      title: "Tenure",
      dataIndex: "tenure",
      key: "tenure",
      render: (value: string) => {
        return <div>{value ? value : "-----"}</div>;
      },
    },
    {
      title: "Interest Rate",
      dataIndex: "interestRate",
      key: "interestRate",
      render: (value: string) => {
        return <div>{value ? value : "-----"}</div>;
      },
    },
    {
      title: "Date Accepted",
      dataIndex: "dateAccepted",
      key: "dateAccepted",
      render: (value: string) => {
        if (value) {
          return <div>{moment(value).format("YYYY-MM-DD")}</div>;
        } else {
          return <div>{"-----"}</div>;
        }
      },
    },
    {
      title: "Loan Product",
      dataIndex: "loanProduct",
      key: "loanProduct",
      render: (value: string) => {
        return <LoanProductTag loanProduct={value} />;
      },
    },
    {
      title: "",
      dataIndex: "loanRequestReference",
      key: "loanRequestReference",
      render: (value: string, row: any) => {
        return (
          <Link
            to={`/dashboard/all-loan-requests-mgt/details/${value}`}
            state={{ ...row, loanRequestStatusTab: status }}
          >
            <Button
              type="primary"
              className="bg-[#0190fe] border-[#0190fe] rounded"
            >
              View Details
            </Button>
          </Link>
        );
      },
    },
  ];
  const handleTableChange = (pagination: any) => {
    fetchData(status, searchParams, pagination);
  };
  const filterLoanRequest = () => {
    const formValues = form.getFieldsValue();
    const allValues = searchParams
      ? { ...searchParams, ...formValues }
      : formValues;

    let validFields = parseTableSearchParams(allValues);

    setSearchParams(validFields);
    fetchData(status, validFields, initPagination);
  };

  const onClearForm = () => {
    form.resetFields();
    setSearchParams(searchParams);
    fetchData(status);
  };
  return (
    <>
      <Card title="Loan Requests Filter" className="my-[18px]" bordered={false}>
        <Form layout="vertical" name="filter-fco" form={form}>
          <Row gutter={24}>
            <Col xs={24} sm={12} md={8}>
              <FormItem
                label="Business Name"
                name={"businessName"}
                initialValue=""
              >
                <Input size="large" placeholder="" />
              </FormItem>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <FormItem
                label="Business Owner Name"
                name={"businessOwnerName"}
                initialValue=""
              >
                <Input size="large" placeholder="" />
              </FormItem>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <FormItem label="BVN" name="businessOwnerBvn" initialValue="">
                <Input size="large" placeholder="" />
              </FormItem>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <FormItem
                label="Account Number"
                name="businessAccountNumber"
                initialValue=""
              >
                <Input size="large" placeholder="" />
              </FormItem>
            </Col>
          </Row>

          <Row gutter={[12, 12]}>
            <Col xs={24} sm={12} md={3}>
              <Button
                type="primary"
                className="w-full rounded"
                danger
                onClick={onClearForm}
              >
                Clear
              </Button>
            </Col>
            <Col xs={24} sm={12} md={4}>
              <Button
                type="primary"
                className="w-full bg-[#0190fe] border-[#0190fe] rounded"
                htmlType="submit"
                onClick={filterLoanRequest}
              >
                Search
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
      <Card title="Loan Requests pending approval" className="my-[18px] w-full">
        <Table
          className="w-full overflow-x-auto"
          dataSource={loanRequests}
          columns={tableColumns}
          rowKey={(record) => record.loanRequestReference}
          loading={loading}
          pagination={pagination}
          onChange={handleTableChange}
        />
      </Card>
    </>
  );
};
export default PendingApprovalLoanRequests;
