import React, { FC } from "react";
import { Descriptions, Divider, Typography, Form } from "antd";
import ImagesDisplay from "../../../../components/ImagesDisplay";

const { Text } = Typography;

interface VerificationAddress {
  addressLineOne: string;
  addressLineTwo: string;
  city: string;
  lga: string;
}

interface Request {
  businessName: string;
  businessCategory: string;
  verificationAddress: VerificationAddress;
  businessImages: string[];
  verificationReferenceId: string;
  createdBy: string;
  createdOn: string;
}
interface Data {
  request: Request;
}

interface Props {
  src: Data;
  data:  any
}

const SilentVerificationRequestViewer: FC<Props> = ({ src, data }) => {
  return (
    <div className="p-[25px]">
      <Descriptions layout="vertical" title="Request Overview" column={2}>
        <Descriptions.Item
          label={<Text strong>Verification Reference ID</Text>}
        >
          {data?.reference}
        </Descriptions.Item>
        <Descriptions.Item label={<Text strong>Business Name</Text>}>
          {src.request?.businessName}
        </Descriptions.Item>
        <Descriptions.Item label={<Text strong>Business Category</Text>}>
          {src.request?.businessCategory}
        </Descriptions.Item>
      </Descriptions>

      <Divider />

      <Descriptions layout="vertical" title="Verification Address" column={2}>
        <Descriptions.Item label={<Text strong>Address Line 1</Text>}>
          {src.request?.verificationAddress.addressLineOne}
        </Descriptions.Item>
        <Descriptions.Item label={<Text strong>Address Line 2</Text>}>
          {src.request?.verificationAddress.addressLineTwo || "N/A"}
        </Descriptions.Item>
        <Descriptions.Item label={<Text strong>City</Text>}>
          {src.request?.verificationAddress.city}
        </Descriptions.Item>
        <Descriptions.Item label={<Text strong>LGA</Text>}>
          {src.request?.verificationAddress.lga}
        </Descriptions.Item>
      </Descriptions>

      <Divider />

      <Typography.Title level={5}>Business Images</Typography.Title>
      <ImagesDisplay imageRefs={src.request?.businessImages ?? []} />
    </div>
  );
};

export default SilentVerificationRequestViewer;
