import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Select, Upload, Button, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import {UploadFile, UploadChangeParam, RcFile} from "antd/es/upload/interface";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  fetchLGAsByStateCode,
  fetchStates,
} from "../../modules/Dashboard/Utils/UtilsSlice/utilsSlice.thunks";
import useCreateSilentVerification from "../../modules/Dashboard/LoanContractsManagement/hooks/useCreateSilentVerification";
import { useImageUpload } from "../../modules/Dashboard/LoanContractsManagement/hooks/useImageUpload";
import { getBase64 } from "../../modules/Dashboard/Utils/FilesUtils/fileSlice/fileSlice.thunk";

const { Option } = Select;

interface CreateSilentVerificationRequestModalProps {
  onClose: () => void;
}

interface FormValues {
  businessName: string;
  businessCategory: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  lga: string;
  businessImages?: string[];
}

const CreateSilentVerificationRequestModal: React.FC<CreateSilentVerificationRequestModalProps> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<FormValues>();
  const { states, LGAsByState } = useAppSelector((state) => state.utils);
  const { createSilentVerification, isLoading: isCreatingRequest } = useCreateSilentVerification();
  const { uploading, uploadImages } = useImageUpload();
  const [fileList, setFileList] = useState<UploadFile<any>[]>([]);
  const [previewState, setPreviewState] = useState({
    image: '',
    title: ''
  });

  const handleFileChange = (info: UploadChangeParam<UploadFile<any>>) => {
    setFileList(info.fileList.slice(-5));
  };

  const beforeUpload = (file: RcFile) => {
    const isImage = ["image/jpeg", "image/png"].includes(file.type);
    if (!isImage) {
      message.error("You can only upload JPG, PNG files!");
      return Upload.LIST_IGNORE;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("File must be smaller than 2MB!");
      return Upload.LIST_IGNORE;
    }
    return false;
  };

  const handleStateChange = (value: string) => {
    if (value) {
      form.setFieldsValue({ lga: undefined });
      dispatch(fetchLGAsByStateCode(value));
    }
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      // Upload images and collect image references
      let imageReferences: string[] = [];
      try {
        imageReferences = await uploadImages(fileList);
      } catch (error) {
        return;
      }
      // Submit the form data
      try {
        await createSilentVerification({
          payload: {
            businessCategory: values.businessCategory,
            verificationAddress: {
              city: values.city,
              lga: values.lga,
              addressLineOne: values.addressLine1,
              addressLineTwo: values.addressLine2 || "",
            },
            businessImages: imageReferences,
            businessName: values.businessName,
          },
          callback: () => onClose(),
        });
      } catch (error) {}
    } catch (errorInfo) {}
  };

  useEffect(() => {
    dispatch(fetchStates());
    // eslint-disable-next-line
  }, []);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
    setPreviewState({
      image: file.url || (file.preview as string),
      title: file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1)
    });
  };

  return (
      <Modal
          title="Create Silent Verification Request"
          open={true}
          onCancel={onClose}
          width={800}
          footer={[
            <Button key="cancel" onClick={onClose} disabled={uploading || isCreatingRequest}>
              Cancel
            </Button>,
            <Button
                key="submit"
                type="primary"
                onClick={handleSubmit}
                loading={uploading || isCreatingRequest}
            >
              Submit
            </Button>,
          ]}
      >
        <Form form={form} layout="vertical">
          <Form.Item
              name="businessName"
              label="Business Name"
              rules={[
                { required: true, message: "Please enter the business name" },
                { max: 100, message: "Business name cannot exceed 250 characters" },
              ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
              name="businessCategory"
              label="Business Category"
              rules={[
                { required: true, message: "Please enter the business category" },
                { max: 50, message: "Business Category cannot exceed 250 characters" },
              ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
              name="addressLine1"
              label="Address Line 1"
              rules={[
                { required: true, message: "Please enter the address line 1" },
                {
                  max: 250,
                  message: "Address Line 1 cannot exceed 250 characters",
                },
              ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
              name="addressLine2"
              label="Address Line 2"
              rules={[
                {
                  max: 250,
                  message: "Address Line 2 cannot exceed 250 characters",
                },
              ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
              name="state"
              label="State"
              rules={[{ required: true, message: "Please select a state" }]}
          >
            <Select
                placeholder="Select a State"
                optionFilterProp="children"
                onChange={handleStateChange}
            >
              {states.map((state) => (
                  <Option
                      key={state.id}
                      value={state.stateCode}
                      name={state.stateName}
                  >
                    {state.stateName}
                  </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
              name="city"
              label="City"
              rules={[{ required: true, message: "Please enter the city" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
              name="lga"
              label="LGA"
              rules={[{ required: true, message: "Please select the LGA" }]}
          >
            <Select placeholder="Select LGA">
              {LGAsByState.map((lga) => (
                  <Option key={lga.id} value={lga.code} name={lga.name}>
                    {lga.name}
                  </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="businessImages" label="Business Images">
            <Upload
                listType="picture-card"
                fileList={fileList}
                onChange={handleFileChange}
                beforeUpload={beforeUpload}
                onPreview={handlePreview}
            >
              {fileList.length < 5 && (
                  <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
              )}
            </Upload>
          </Form.Item>
          <Modal
              open={!!previewState.image}
              title={previewState.title}
              footer={null}
              onCancel={() => setPreviewState({
                image: '',
                title: ''
              })}
          >
            <img
                alt="business image"
                style={{ width: "100%" }}
                src={previewState.image}
            />
          </Modal>
        </Form>
      </Modal>
  );
};

export default CreateSilentVerificationRequestModal;
