import { Modal, Typography } from "antd";

const { Title } = Typography;

interface OVDConversionConfirmationModalProps {
  visible: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const OVDConversionConfirmationModal = ({
  visible,
  onConfirm,
  onCancel,
}: OVDConversionConfirmationModalProps) => {
  return (
    <Modal
      destroyOnClose={true}
      centered
      open={visible}
      title={<Title level={4}>Confirm Changes</Title>}
      width={800}
      okText="Convert to OVD"
      onOk={onConfirm}
      onCancel={onCancel}
    >
      <div style={{ textAlign: "center" }}>
        <h3>Are you sure you want to convert this loan offer to Overdraft?</h3>
      </div>
    </Modal>
  );
};

export default OVDConversionConfirmationModal;
