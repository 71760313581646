import {
  Alert,
  Button,
  Modal,
  Table,
  Tag,
  Typography,
  Form,
  Input,
} from "antd";
import { Store } from "antd/lib/form/interface";
import React, { useEffect, useState } from "react";

import moment from "moment";
import {
  LoanRequestVerificationAcceptanceStatus,
  LoanRequestVerificationStatus,
} from "../loanRequestSlice/loanRequest.types";
import { convertStatusTextToColor } from "../../../../utils/workflow";
import { useResendLoanRequestVerification } from "./hooks/useResendVerification";
import { useCheckPendingSkippedRequests } from "./hooks/useCheckPendingSkippedRequests";
import SubmitSkipVerificationRequest from "./SubmitSkipVerificationRequest";
import upperCase from "lodash/upperCase";
import {Feature} from "../../../../config/feature-config";
import config from "../../../../config";
import {maxCommentLength, minCommentLength} from "../utils/constants";

export interface VerificationDetails {
  reference: string;
  type: string;
  verificationDate: string;
  acceptanceStatus: string;
  status: string;
  createdDate: string;
  name: string;
  verificationReference?: string;
  hasPendingRequest?: boolean;
}

interface Props {
  data: VerificationDetails[];
  loanRequestReference?: string;
}
interface RowItem {
  type: string;
  verificationDate: string;
  status: string;
  acceptanceStatus: string;
  reference: string;
}
const FormItem = Form.Item;
const LoanRequestVerificationTable: React.FC<Props> = ({
  data,
  loanRequestReference,
}) => {
  const [modalView, setModalView] = useState<boolean>(false);
  const [verificationRef, setVerificationRef] = useState<string>("");
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const [loanVerificationRequests, setLoanVerificationRequests] = useState<
    VerificationDetails[]
  >([]);
  const [showSkipVerificationModal, setShowSkipVerificationModal] =
    useState(false);
  const [selectedVerificationRequest, setSelectedVerificationRequest] =
    useState<VerificationDetails | undefined>();

  const {
    resendLoanRequestVerificatioLoading,
    resendLoanRequestVerificatioError,
    resendLoanRequestVerification,
    clearResendVerificationLoanRequestError,
  } = useResendLoanRequestVerification();

  const {
    loading: isLoadingCheckPendingSkippedRequests,
    pendingSkipRequestEligibility,
    getPendingSkipRequestEligibility,
  } = useCheckPendingSkippedRequests();

  useEffect(() => {
    forceUpdate({});
  }, []);

  const handlePendingSkipRequestEligibility = (
    data: VerificationDetails[],
    loanRequestReference?: string,
  ) => {
    // Find the first record with status 'SENT_FOR_VERIFICATION'
    const recordSentForVerification = data?.find(
      (value) =>
        value.status === LoanRequestVerificationStatus.SENT_FOR_VERIFICATION,
    );
    // If such a record exists and loanRequestReference is provided, fetch pending skip request eligibility
    if (recordSentForVerification && loanRequestReference) {
      getPendingSkipRequestEligibility(loanRequestReference);
    }
  };

  useEffect(() => {
    handlePendingSkipRequestEligibility(data, loanRequestReference);
  }, [loanRequestReference, data]);

  /**
   * Effect to update loan verification requests with pending skip request eligibility.
   * If there are pending skip requests, it updates the data with the pending request information.
   */
  useEffect(() => {
    if (!!pendingSkipRequestEligibility?.length) {
      const updatedData = data?.map((item) => {
        // Find the pending request that matches the current item's reference
        const pendingRequest = pendingSkipRequestEligibility?.find(
          (request) => request.verificationReference === item.reference,
        );
        return {
          ...item,
          hasPendingRequest: pendingRequest?.hasPendingRequest,
          verificationReference: pendingRequest?.verificationReference,
        };
      });
      setLoanVerificationRequests(updatedData);
    } else {
      setLoanVerificationRequests(data);
    }
  }, [pendingSkipRequestEligibility, data]);

  const clearError = () => {
    clearResendVerificationLoanRequestError();
  };

  const onCloseModal = () => {
    setModalView(false);
    clearError();
  };
  const openModal = (verificationRef: string) => {
    setModalView(true);
    setVerificationRef(verificationRef);
  };

  const tableColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (value: string) => {
        return <div>{value}</div>;
      },
    },
    {
      title: "Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (value: string) => {
        if (value) {
          return <div>{moment(value).format("YYYY-MM-DD")}</div>;
        } else {
          return <div>{"-----"}</div>;
        }
      },
    },
    {
      title: "Verification Type",
      dataIndex: "type",
      key: "type",
      render: (value: string) => {
        return <div>{value}</div>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value: string) => (
        <Tag color={convertStatusTextToColor(value)}>{upperCase(value)}</Tag>
      ),
    },
    {
      title: "Acceptance Status",
      dataIndex: "acceptanceStatus",
      key: "acceptanceStatus",
      render: (value: string) => {
        return (
          <Tag color={convertStatusTextToColor(value)}>{upperCase(value)}</Tag>
        );
      },
    },
    {
      title: "",
      dataIndex: "reference",
      key: "reference",
      render: (value: string, row: RowItem) => {
        if (
          row.acceptanceStatus ===
            LoanRequestVerificationAcceptanceStatus.ACCEPTED &&
          row.status === LoanRequestVerificationStatus.FAILED
        )
          return (
            <Button
              type="primary"
              className="bg-[#0190fe] border-[#0190fe] rounded"
              onClick={() => openModal(value)}
            >
              Resend Verification
            </Button>
          );
      },
    },
    {
      title: "Action",
      dataIndex: "status",
      key: "status",
      render: (value: string, row: VerificationDetails) => {
        return (
            <div>
              <Button
                  type="link"
                  disabled={row?.hasPendingRequest || row?.status !==
                      LoanRequestVerificationStatus.SENT_FOR_VERIFICATION }
                  onClick={() => {
                    setSelectedVerificationRequest(row);
                    setShowSkipVerificationModal(true);
                  }}
              >
                Skip Field Verification
              </Button>
            </div>
        );
      },
    },
  ];

  const onFinish = (values: Store) => {
    if (verificationRef && values && values?.reason) {
      const paylod = {
        verificationReference: verificationRef,
        reverificationReason: values?.reason,
      };
      resendLoanRequestVerification(paylod, () => {
        onCloseModal();
      });
    }
  };

  return (
    <>
      <Table
        className="w-full overflow-x-auto"
        dataSource={loanVerificationRequests}
        columns={tableColumns}
        rowKey={(record) => record.reference}
        loading={false}
      />
      <SubmitSkipVerificationRequest
        data={selectedVerificationRequest}
        onCancel={() => {
          setShowSkipVerificationModal(false);
          handlePendingSkipRequestEligibility(data, loanRequestReference);
        }}
        visible={showSkipVerificationModal}
        loanRequestReference={loanRequestReference}
      />
      <Modal
        destroyOnClose={true}
        centered
        className="modal-lg my-6"
        width={900}
        open={modalView}
        onCancel={onCloseModal}
        title={
          <Typography.Title level={4}>{"Resend Verification"}</Typography.Title>
        }
        footer={null}
      >
        {resendLoanRequestVerificatioError && (
          <Alert
            message={resendLoanRequestVerificatioError}
            type="error"
            closable
            className="mt-4"
            onClose={clearError}
          />
        )}
        <Form
          layout="vertical"
          form={form}
          name="resend-verification"
          onFinish={onFinish}
        >
          <FormItem
            label="Reason"
            name="reason"
            rules={[
              {
                required: true,
                message: "Reason cannot be empty",
              },
              { min: minCommentLength, message: `Minimum of ${minCommentLength} characters` },
              { max: maxCommentLength, message: `Maximum of ${maxCommentLength} characters` },
            ]}
          >
            <Input.TextArea
              size="large"
              placeholder=""
              className="w-full"
              rows={4}
              showCount
              maxLength={maxCommentLength}
            />
          </FormItem>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldsValue }) => {
              const { reason } = getFieldsValue();
              const formIsComplete = !!reason;
              return (
                <div className="flex justify-end mt-[30px]">
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={
                      !formIsComplete ||
                      form
                        .getFieldsError()
                        .filter(({ errors }) => errors.length).length > 0
                    }
                    loading={resendLoanRequestVerificatioLoading}
                  >
                    Submit
                  </Button>
                </div>
              );
            }}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default LoanRequestVerificationTable;
