import React from "react";
import { SubmitSkipVerificationRequestType } from "../../loanRequestSlice/loanRequest.types";
import { submit_skip_verification_request_service } from "../../loanRequestApi";
import { showMessage } from "../../../../../utils/notifications";
import { parseError } from "../../../../../utils/server";

export const useSubmitSkipVerification = () => {
  const [submitSkipVerificationLoading, setSubmitSkipVerificationLoading] =
    React.useState<boolean>(false);

  const submitSkipVerification = async (
    payLoad: SubmitSkipVerificationRequestType,

    callBack?: () => void,
  ) => {
    try {
      setSubmitSkipVerificationLoading(true);
      await submit_skip_verification_request_service(payLoad);
      showMessage(
        "SUCCESSFUL",
        "Your request to skip verification has been successfully submitted. Verification will be skipped once approval is granted.",
        "success",
      );
      if (callBack) {
        callBack();
      }
    } catch (error: any) {
      const errorMessage = parseError(error);

      showMessage("Request Failed", errorMessage, "error");
    } finally {
      setSubmitSkipVerificationLoading(false);
    }
  };
  return {
    submitSkipVerificationLoading,
    submitSkipVerification,
  };
};
