import { Layout, Menu, SiderProps } from "antd";
import { SubMenuType } from "antd/lib/menu/hooks/useItems";
import { FC, useCallback, useMemo } from "react";
import LocalIcons from "../../assets/SVGs";
import config from "../../config";
import { Feature } from "../../config/feature-config";
import { MenuItem } from "../../routes/Dashboard/menuItems";
import useWindowDimensions from "../../utils/DOM";

const { Sider } = Layout;

interface SideBarProps {
  collapsed: boolean;
  onCollapse(value: boolean): void;
  menuItems: MenuItem[];
}

const SideBar: FC<SideBarProps> = ({ collapsed, onCollapse, menuItems }) => {
  const { width: windowWidth } = useWindowDimensions();

  const breakpointParams: SiderProps =
    windowWidth <= 1024 ? { breakpoint: "lg", collapsedWidth: "0" } : {};

  const filterMenuItems = useCallback(
    (menuItems: SubMenuType[], excludedFeatures: Feature[]) => {
      return menuItems.filter((item) => {
        // Check if the item itself is excluded
        if (excludedFeatures.includes(item?.key as Feature)) {
          if (excludedFeatures.includes(item?.key as Feature)) {
            return false;
          }
          return false;
        }

        // Check if the item has children
        if (item.children) {
          // Filter the feature children recursively
          item.children = filterMenuItems(
            item.children as SubMenuType[],
            excludedFeatures
          );
          // Remove feature if all its children are excluded
          if (item.children.length === 0) {
            return false;
          }
        }

        return true;
      });
    },
    []
  );

  const menusToRender = useMemo(() => {
    return filterMenuItems(
      menuItems as SubMenuType[],
      config?.excluded_features || []
    );
  }, [menuItems, filterMenuItems]);

  return (
    <Sider
      {...breakpointParams}
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
    >
      {collapsed ? (
        <div className="h-[32px] my-6 px-6 flex items-center">
          <img
            src={LocalIcons.MoniepointIcon}
            alt="moniepoint"
            className="max-h-[1.8rem]"
          />
        </div>
      ) : (
        <div className="h-[32px] my-6 px-6 flex items-center">
          <img
            src={LocalIcons.MoniepointLight}
            alt="moniepoint"
            className="max-h-[1.8rem]"
          />
        </div>
      )}
      <Menu
        theme="dark"
        defaultSelectedKeys={["dashboard"]}
        mode="inline"
        items={menusToRender}
      />
    </Sider>
  );
};

export default SideBar;
