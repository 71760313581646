import { useEffect, useState } from "react"
import { useAppDispatch } from "../../../../../store"
import { fetchFileUrl } from "../fileSlice/fileSlice.thunk"


export const useFileRef = (fileRef: string) => {
    const dispatch = useAppDispatch()
    const [image, setImage] = useState("")
    const [error, setError] = useState<string | null>(null);


    useEffect(() => {
        if (fileRef) {
            dispatch(fetchFileUrl(fileRef, (url, error) => {
                setImage(url)
                if (error) {
                    setError(error)
                }
            }))
        } else {
            setImage('')

        }
    }, [dispatch, fileRef])

    return ({ image, error })
}

