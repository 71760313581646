export enum WorkflowCategory {
  WORKING_CAPITAL_LOANS = "WORKING_CAPITAL_LOANS",
  PORTFOLIO_MANAGEMENT = "PORTFOLIO_MANAGEMENT",
  WORKING_CAPITAL_LOANS_BO = "WORKING_CAPITAL_LOANS_BO",
}
export const WorkflowShouldValidateComments = {
  [WorkflowCategory.WORKING_CAPITAL_LOANS]: false,
  [WorkflowCategory.PORTFOLIO_MANAGEMENT]: false,
  [WorkflowCategory.WORKING_CAPITAL_LOANS_BO]: true,
};

export const mockWorkFlowTypes = [
  { type: "LOAN_OFFER_UPDATE", description: "Update a loan offer" },
  // TODO : - Update to the correct Workflow type from the api
  { type: "WCL_OFFER_TO_OVD", description: "Update WCL to OVD Offer" },
  { type: "SKIP_FIELD_VERIFICATION", description: "Skip Field Verification" },
];

export const mockPendingWorkflowRequest = {
  content: [
    {
      description: "Update WCL to OVD Offer",
      actionParameters:
        '{"request":{"loanProduct":"OVD","updateRequest":{"OVD":{"id":202,"loanAccountNuban":"6000352496","businessOwnerCode":"MP|AGENT_MANAGER|7AFD04AB03D9E38E5B390576AF9BF616","loanRequestReference":"WCL|REQ|122845491CA24F59B6EC13FD65C80E31","offerReference":"LO|BE476B60-4203-4015-9882-6775852E44C3","regularizationPeriodUnit":"DAYS","regularizationPeriod":5,"maxDefaultCountToTermination":5,"amount":4573786.87,"dailyInterestRateInPercentage":1,"hourlyInterestRateInPercentage":0.04167,"loanTenureUnit":"WEEKLY","tenure":"13","processingFee":0,"managementFee":45737.87,"loanProduct":"OVD","interestCalculationFrequency":"DAILY","loanRequestStatus":"PERUSING_LOAN_OFFER","loanTenure":"13"}}},"loanOfferRef":"LO|BE476B60-4203-4015-9882-6775852E44C3","initialOffer":{"loanProduct":"OVD","offerResponse":{"OVD":{"id":202,"loanAccountNuban":"6000352496","businessOwnerCode":"MP|AGENT_MANAGER|7AFD04AB03D9E38E5B390576AF9BF616","loanRequestReference":"WCL|REQ|122845491CA24F59B6EC13FD65C80E31","offerReference":"LO|BE476B60-4203-4015-9882-6775852E44C3","regularizationPeriodUnit":"DAYS","regularizationPeriod":5,"maxDefaultCountToTermination":5,"amount":4573786.87,"dailyInterestRateInPercentage":1.0,"hourlyInterestRateInPercentage":0.04167,"loanTenureUnit":"WEEKLY","tenure":25,"processingFee":0.00,"managementFee":45737.87,"loanProduct":"OVERDRAFT","interestCalculationFrequency":"DAILY","loanRequestStatus":"PERUSING_LOAN_OFFER"}}},"parameter_data_config_key":{"0":{"parameterName":"request","classType":"com.teamapt.loansbackofficeservice.module.loanoffer.request.OfferUpdateRequestDTO","parameterizedClassType":null,"keyClassType":null},"1":{"parameterName":"loanOfferRef","classType":"java.lang.String","parameterizedClassType":null,"keyClassType":null},"2":{"parameterName":"initialOffer","classType":"com.teamapt.loansbackofficeservice.module.loanoffer.response.OfferDetailsForWorkflowResponseDTO","parameterizedClassType":null,"keyClassType":null}}}',
      initiator: "wcl_backoffice_user2",
      rootReference: "0E6A97A8F3474B7B96F37943A8036143",
      reference: "0E6A97A8F3474B7B96F37943A8036143",
      nextReference: null,
      authorizer: "wcl_backoffice_user",
      authorizersComment: null,
      workflowServiceIdentifier: null,
      workflowRequestType: "WCL_OFFER_TO_OVD",
      requestStatus: "Pending",
      createdOn: "2024-08-01 23:15:06",
      lastModifiedOn: "2024-08-01 23:16:07",
    },
    {
      description: "Update WCL to OVD Offer",
      actionParameters:
        '{"request":{"loanProduct":"WCL","updateRequest":{"WCL":{"id":203,"loanAccountNuban":"6000352497","businessOwnerCode":"MP|AGENT_MANAGER|7AFD04AB03D9E38E5B390576AF9BF616","loanRequestReference":"WCL|REQ|122845491CA24F59B6EC13FD65C80E32","offerReference":"LO|BE476B60-4203-4015-9882-6775852E44C4","regularizationPeriodUnit":"DAYS","regularizationPeriod":5,"maxDefaultCountToTermination":5,"amount":4573786.87,"dailyInterestRateInPercentage":1,"hourlyInterestRateInPercentage":0.04167,"loanTenureUnit":"WEEKLY","tenure":"13","processingFee":0,"managementFee":45737.87,"loanProduct":"WCL","interestCalculationFrequency":"DAILY","loanRequestStatus":"PENDING_APPROVAL","loanTenure":"13"}}},"loanOfferRef":"LO|BE476B60-4203-4015-9882-6775852E44C4","initialOffer":{"loanProduct":"WCL","offerResponse":{"WCL":{"id":203,"loanAccountNuban":"6000352497","businessOwnerCode":"MP|AGENT_MANAGER|7AFD04AB03D9E38E5B390576AF9BF616","loanRequestReference":"WCL|REQ|122845491CA24F59B6EC13FD65C80E32","offerReference":"LO|BE476B60-4203-4015-9882-6775852E44C4","regularizationPeriodUnit":"DAYS","regularizationPeriod":5,"maxDefaultCountToTermination":5,"amount":4573786.87,"dailyInterestRateInPercentage":1.0,"hourlyInterestRateInPercentage":0.04167,"loanTenureUnit":"WEEKLY","tenure":25,"processingFee":0.00,"managementFee":45737.87,"loanProduct":"WORKING_CAPITAL","interestCalculationFrequency":"DAILY","loanRequestStatus":"PENDING_APPROVAL"}}},"parameter_data_config_key":{"0":{"parameterName":"request","classType":"com.teamapt.loansbackofficeservice.module.loanoffer.request.OfferUpdateRequestDTO","parameterizedClassType":null,"keyClassType":null},"1":{"parameterName":"loanOfferRef","classType":"java.lang.String","parameterizedClassType":null,"keyClassType":null},"2":{"parameterName":"initialOffer","classType":"com.teamapt.loansbackofficeservice.module.loanoffer.response.OfferDetailsForWorkflowResponseDTO","parameterizedClassType":null,"keyClassType":null}}}',
      initiator: "alice_johnson",
      rootReference: "1A7D9C02B45A4F678EACEDCF2C123456",
      reference: "1A7D9C02B45A4F678EACEDCF2C123456",
      nextReference: null,
      authorizer: "bob_jones",
      authorizersComment: null,
      workflowServiceIdentifier: null,
      workflowRequestType: "WCL_OFFER_TO_OVD",
      requestStatus: "Pending",
      createdOn: "2024-08-02 10:45:30",
      lastModifiedOn: "2024-08-02 11:00:00",
    },
    {
      description: "Update a loan offer",
      actionParameters:
        '{"request":{"loanProduct":"BUSINESS","updateRequest":{"BUSINESS":{"id":204,"loanAccountNuban":"6000352498","businessOwnerCode":"MP|AGENT_MANAGER|7AFD04AB03D9E38E5B390576AF9BF616","loanRequestReference":"WCL|REQ|122845491CA24F59B6EC13FD65C80E33","offerReference":"LO|BE476B60-4203-4015-9882-6775852E44C5","regularizationPeriodUnit":"DAYS","regularizationPeriod":5,"maxDefaultCountToTermination":5,"amount":4573786.87,"dailyInterestRateInPercentage":1,"hourlyInterestRateInPercentage":0.04167,"loanTenureUnit":"WEEKLY","tenure":"13","processingFee":0,"managementFee":45737.87,"loanProduct":"BUSINESS","interestCalculationFrequency":"DAILY","loanRequestStatus":"UNDER_REVIEW","loanTenure":"13"}}},"loanOfferRef":"LO|BE476B60-4203-4015-9882-6775852E44C5","initialOffer":{"loanProduct":"BUSINESS","offerResponse":{"BUSINESS":{"id":204,"loanAccountNuban":"6000352498","businessOwnerCode":"MP|AGENT_MANAGER|7AFD04AB03D9E38E5B390576AF9BF616","loanRequestReference":"WCL|REQ|122845491CA24F59B6EC13FD65C80E33","offerReference":"LO|BE476B60-4203-4015-9882-6775852E44C5","regularizationPeriodUnit":"DAYS","regularizationPeriod":5,"maxDefaultCountToTermination":5,"amount":4573786.87,"dailyInterestRateInPercentage":1.0,"hourlyInterestRateInPercentage":0.04167,"loanTenureUnit":"WEEKLY","tenure":25,"processingFee":0.00,"managementFee":45737.87,"loanProduct":"BUSINESS","interestCalculationFrequency":"DAILY","loanRequestStatus":"UNDER_REVIEW"}}},"parameter_data_config_key":{"0":{"parameterName":"request","classType":"com.teamapt.loansbackofficeservice.module.loanoffer.request.OfferUpdateRequestDTO","parameterizedClassType":null,"keyClassType":null},"1":{"parameterName":"loanOfferRef","classType":"java.lang.String","parameterizedClassType":null,"keyClassType":null},"2":{"parameterName":"initialOffer","classType":"com.teamapt.loansbackofficeservice.module.loanoffer.response.OfferDetailsForWorkflowResponseDTO","parameterizedClassType":null,"keyClassType":null}}}',
      initiator: "michael_williams",
      rootReference: "2B7E9C02B45A4F678EACEDCF2C789012",
      reference: "2B7E9C02B45A4F678EACEDCF2C789012",
      nextReference: null,
      authorizer: "susan_taylor",
      authorizersComment: null,
      workflowServiceIdentifier: null,
      workflowRequestType: "LOAN_OFFER_UPDATE",
      requestStatus: "Pending",
      createdOn: "2024-08-03 14:20:45",
      lastModifiedOn: "2024-08-03 14:35:10",
    },
    {
      description: "Skip Field Verification",
      actionParameters:
        '{"skipFieldVerificationRequestDTO": {"loanRequestReference": "WCL|REQ|FCFC451DA95D7E83A04A85ECF7898323", "verificationReference": "FVRb72agrp2k9vnyA7Vk0bxysoxynfSK2S1T", "comment": "random"}, "parameter_data_config_key": {"0": {"parameterName": "skipFieldVerificationRequestDTO", "classType": "com.teamapt.loansbackofficeservice.module.fieldverification.request.SkipFieldVerificationRequestDTO", "parameterizedClassType": null, "keyClassType": null}}}',
      initiator: "michael_williams",
      rootReference: "WCL|REQ|521EA0493D769569CD2B5682FB612394",
      reference: "WCL|REQ|521EA0493D769569CD2B5682FB612394",
      nextReference: null,
      authorizer: "susan_taylor",
      authorizersComment: null,
      workflowServiceIdentifier: null,
      workflowRequestType: "SKIP_FIELD_VERIFICATION",
      requestStatus: "Pending",
      createdOn: "2024-08-03 14:20:45",
      lastModifiedOn: "2024-08-03 14:35:10",
    },
  ],
  pageable: {
    sort: {
      sorted: true,
      unsorted: false,
      empty: false,
    },
    pageNumber: 0,
    pageSize: 10,
    offset: 0,
    paged: true,
    unpaged: false,
  },
  last: true,
  totalPages: 1,
  totalElements: 3,
  sort: {
    sorted: true,
    unsorted: false,
    empty: false,
  },
  first: true,
  numberOfElements: 3,
  size: 10,
  number: 0,
  empty: false,
};
