import config from "../../../../../config";
import server from "../../../../../server";
import { initPagination } from "../../../../../utils/pagination";
import { loanRequestStatusAPIPath } from "../../loanRequestSlice/loanRequest.types";
import {
  mockApprovalRequestStatus,
  mockLoanEligibilityDetails,
  mockLoanManagers,
  mockLoanRequestApprovalDetails,
  mockLoanRequestDetails,
  mockWCLConversionEligibility,
} from "../../../../../utils/mock";

const baseURL = config.WCL_LOANS_BO_BASE_URL;

export const get_all_loan_requests_service = async (
  status: string,
  searchParams: Record<string, any> = {},
  pagination: Record<string, any> = initPagination,
) => {
  let page = pagination.current - 1;
  const response = await server.get(
    `${baseURL}/loan-requests/${get_api_url(status)}`,
    {
      params: { ...searchParams, page: page, size: pagination.pageSize },
    },
  );

  return response.data;
};
export const get_loan_offer_details_service = async (
  loanProduct: string,
  loanReference: string,
) => {
  const response = await server.get(
    `${baseURL}/offer/${loanProduct}/${loanReference}`,
  );
  return response.data;
};

export const get_loan_offer_audit_log_service = async (
  loanProduct: string,
  loanReference: string,
  workflowReference: string,
) => {
  const response = await server.get(
    `${baseURL}/loan-offer-approval-requests?workflowReference=${workflowReference}`,
  );
  return response.data;
};

export const save_loan_offer_details_service = async (
  loanProduct: string,
  loanReference: string,
  loanOfferDetails: any,
) => {
  const response = await server.put(`${baseURL}/offer/${loanReference}`, {
    ...loanOfferDetails,
  });
  return response.data;
};

export const get_api_url = (status: string) => {
  return loanRequestStatusAPIPath[status];
};

export const get_loan_details_service = async (loanReference: string) => {
  if (config.React_App_Mock) {
    return new Promise((resolve) =>
      setTimeout(() => resolve(mockLoanRequestDetails), 2000),
    );
  } else {
    const response = await server.get(
      `${baseURL}/loan-requests/${loanReference}/details`,
    );
    return response.data;
  }
};

export const get_loan_managers_service = async (loanReference: string) => {
  if (config.React_App_Mock) {
    return new Promise((resolve) =>
      setTimeout(() => resolve(mockLoanManagers), 2000),
    );
  } else {
    const response = await server.get(
      `${baseURL}/loan-requests/${loanReference}/loan-managers`,
    );
    return response.data;
  }
};
export const get_loan_eligibility_service = async (
  businessNubanAccountNumber: string,
) => {
  if (config.React_App_Mock) {
    return new Promise((resolve) =>
      setTimeout(() => resolve(mockLoanEligibilityDetails), 2000),
    );
  } else {
    const response = await server.get(
      `${baseURL}/business-owner/${businessNubanAccountNumber}/loan/access-details`,
    );
    return response.data;
  }
};

export const enable_loan_for_business_owner_service = async (
  businessOwnerCode: string,
) => {
  if (config.React_App_Mock) {
    return new Promise((resolve) => setTimeout(() => resolve("Success"), 2000));
  } else {
    const response = await server.post(
      `${baseURL}/business-owner/loan/grant-access`,
      { businessOwnerCode },
    );
    return response.data;
  }
};

export const wcl_to_ovd_conversion_service = async (loanOfferDetails: any) => {
  if (config.React_App_Mock) {
    return new Promise((resolve) =>
      setTimeout(() => resolve(mockLoanRequestDetails), 2000),
    );
  } else {
    const response = await server.post(
      `${baseURL}/overdraft/offer`,
      loanOfferDetails,
    );
    return response.data;
  }
};

export const approval_request_status_service = async (
  offerReference: string,
) => {
  if (config.React_App_Mock) {
    return new Promise((resolve) =>
      setTimeout(() => resolve(mockApprovalRequestStatus), 2000),
    );
  } else {
    const response = await server.get(
      `${baseURL}/overdraft/${offerReference}/approval-request-status`,
    );
    return response.data;
  }
};

export const wcl_to_ovd_conversion_eligibility_service = async (
  loanReference: string,
) => {
  if (config.React_App_Mock) {
    return new Promise((resolve) =>
      setTimeout(() => resolve(mockWCLConversionEligibility), 2000),
    );
  } else {
    const response = await server.get(
      `${baseURL}/overdraft/offer/${loanReference}/ovd-conversion-eligibility`,
    );
    return response.data;
  }
};

export const get_loan__approval_details_service = async (
  loanReference: string,
) => {
  if (config.React_App_Mock) {
    return new Promise((resolve) =>
      setTimeout(() => resolve(mockLoanRequestApprovalDetails), 2000),
    );
  } else {
    const response = await server.get(
      `${baseURL}/loan-requests/${loanReference}/approval-details`,
    );
    return response.data;
  }
};
