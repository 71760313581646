import React from "react";
import { DatePicker, Form, Input, Modal, Typography } from "antd";
import moment from "moment";
import { showMessage } from "../../utils/notifications";
import useCreateInterestWaiver from "../../modules/Dashboard/LoanContractsManagement/hooks/useCreateInterestWaiver";
import useQueryPendingApprovalRequest from "../../modules/Dashboard/LoanContractsManagement/hooks/useQueryPendingApprovalRequest";
import {maxCommentLength, minCommentLength} from "../../modules/Dashboard/LoanRequestsManagement/utils/constants";

const { Title } = Typography;
const { TextArea } = Input;

interface ConfirmWaiveInterestModalProps {
  onClose: () => void;
  contractRef: string;
}

const ConfirmWaiveInterestModal = ({ onClose, contractRef }: ConfirmWaiveInterestModalProps) => {
  const { isLoading, createInterestWaiver } = useCreateInterestWaiver();

  const { checkPendingApprovalRequest } = useQueryPendingApprovalRequest({
    ref: contractRef,
  });

  const [form] = Form.useForm();

 const handleCreateInterestWaiver = () => {
   form
     .validateFields()
     .then(async () => {
       await createInterestWaiver({
         payload: {
           contractReference: contractRef,
           startDate: form.getFieldValue("startDate").format("YYYY-MM-DD"),
           endDate: form.getFieldValue("endDate")
             ? form.getFieldValue("endDate").format("YYYY-MM-DD")
             : undefined,
           comment: form.getFieldValue("comments"),
         },
         callback: () => {
           showMessage("SuccessFul", "Request has been sent for approval.");
           checkPendingApprovalRequest();
           form.resetFields();
           onClose();
         },
       });
     })
     .catch(() => {});
 };

  return (
    <div>
      <Modal
        destroyOnClose={true}
        centered
        open={true}
        title={<Title level={4}>Waive Interest</Title>}
        width={800}
        okText="Confirm"
        confirmLoading={isLoading}
        onOk={handleCreateInterestWaiver}
        onCancel={() => onClose()}
      >
        <Form
          form={form}
          layout="vertical"
          name="waive_interest_form"
         initialValues={{ startDate: moment().add(1, 'days') }}
          size="large"
        >
          <Form.Item
            name="startDate"
            label="Start Date"
            rules={[
              { required: true, message: "Please select a start date" },
              () => ({
                validator(_, value) {
                  if (!value || value.isAfter(moment())) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Start date must be greater than current date"),
                  );
                },
              }),
            ]}
            style={{ width: "100%" }}
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            name="endDate"
            label="End Date"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || value.isAfter(getFieldValue("startDate"))) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("End date must be greater than start date"),
                  );
                },
              }),
            ]}
            style={{ width: "100%" }}
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="comments"
            label="Comments"
            hasFeedback
            rules={[
                {
                    required: true,
                    message: "Comment must be at least 6 characters",
                },
                { min: minCommentLength },
                { max: maxCommentLength },
            ]}
          >
            <TextArea rows={4} showCount maxLength={255} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ConfirmWaiveInterestModal;
