import { useLoadingAndErrorState } from "../../AllLoanRequests/hooks/useLoadingAndErrorState";
import { useCallback, useState } from "react";
import { showMessage } from "../../../../../utils/notifications";
import { parseError } from "../../../../../utils/server";
import { check_for_pending_skip_requests_service } from "../../loanRequestApi";

interface PendingSkipRequest {
  verificationReference: string;
  hasPendingRequest: boolean;
}

export const useCheckPendingSkippedRequests = () => {
  const { loading, setLoading, setLoadingError, setPermissionError } =
    useLoadingAndErrorState();
  const [pendingSkipRequestEligibility, setPendingSkipRequestEligibility] =
    useState<PendingSkipRequest[] | null>(null);

  const getPendingSkipRequestEligibility = useCallback(
    async (loanRequestReference: string) => {
      try {
        setLoading(true);
        setLoadingError(false);
        const response =
          await check_for_pending_skip_requests_service(loanRequestReference);
        setPendingSkipRequestEligibility(response);
      } catch (error: any) {
        if (error.response && error.response.status === 403) {
          setPermissionError(true);
          return;
        }
        showMessage("Error", parseError(error), "error");
        setLoadingError(true, parseError(error));
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  return {
    getPendingSkipRequestEligibility,
    loading,
    pendingSkipRequestEligibility,
  };
};
