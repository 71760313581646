import React, { FC } from "react";
import { Descriptions, Divider, Typography } from "antd";
import { parseAmountToCurrencyValue } from "../../../../../utils/dataParser";

interface ClientContractDetailsDTO {
  businessName: string;
  businessOwnerName: string;
  contractRef: string;
  contractState: string;
  contractStatus: string;
  loanAmount: number;
  loanProduct: string;
  loanRequestRef: string;
}

interface CreateInterestWaivePeriodRequest {
  comment: string;
  contractReference: string;
  endDate: [number, number, number];
  startDate: [number, number, number];
}

interface Props {
  src: {
    clientContractDetailsDTO: ClientContractDetailsDTO;
    createInterestWaivePeriodRequest: CreateInterestWaivePeriodRequest;
  };
}

const CreateInterestWaiveApprovalViewer: FC<Props> = ({ src }) => {
  return (
    <div className="p-[25px]">
      <Descriptions layout="vertical" title="Waive Period Information">
        <Descriptions.Item
          label={<Typography.Text strong>Start Date</Typography.Text>}
        >
          {src.createInterestWaivePeriodRequest.startDate.join("-")}
        </Descriptions.Item>
        <Descriptions.Item
          label={<Typography.Text strong>End Date</Typography.Text>}
        >
          {src.createInterestWaivePeriodRequest.endDate
            ? src.createInterestWaivePeriodRequest.endDate.join("-")
            : "Indefinite"}
        </Descriptions.Item>
        <Descriptions.Item
          label={<Typography.Text strong>Comments</Typography.Text>}
        >
          {src.createInterestWaivePeriodRequest.comment}
        </Descriptions.Item>
      </Descriptions>
      <Divider />
      <Descriptions layout="vertical" title="Business Details">
        <Descriptions.Item
          label={<Typography.Text strong>Business Name</Typography.Text>}
        >
          {src.clientContractDetailsDTO?.businessName}
        </Descriptions.Item>
        <Descriptions.Item
          label={<Typography.Text strong>Business Owner Name</Typography.Text>}
        >
          {src.clientContractDetailsDTO?.businessOwnerName}
        </Descriptions.Item>
        <Descriptions.Item
          label={<Typography.Text strong>Loan Amount</Typography.Text>}
        >
          {parseAmountToCurrencyValue(src.clientContractDetailsDTO?.loanAmount, "₦")}
        </Descriptions.Item>
        <Descriptions.Item
          label={<Typography.Text strong>Loan Product</Typography.Text>}
        >
          {src.clientContractDetailsDTO?.loanProduct}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};
export default CreateInterestWaiveApprovalViewer;
