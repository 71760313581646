import { fetchWorkflowData, fetchWorkflowTypes, resetWorkflowData, setWorkflowCurrentPage, setWorkflowsLoading } from ".";
import { AppThunk } from "../../../../store";
import { showMessage, showServerError } from "../../../../utils/notifications";
import { initPagination } from "../../../../utils/pagination";
import { parseError } from "../../../../utils/server";
import { api_fetch_workflow_requests, api_fetch_workflow_types, api_process_workflow, COMPLETED_REQUESTS_URL_MAP, PENDING_REQUESTS_URL_MAP } from "../workflowApi";
import { WorkflowCategory } from "./workflowSlice.types";


export const fetchWorkFlowRequests = (
    url: string,
    searchParams?: Record<string, any>,
    pagination?: Record<string, any>
): AppThunk => async (dispatch) => {
    try {
        dispatch(setWorkflowsLoading(true))

        const response = await api_fetch_workflow_requests(url, searchParams, pagination)

        if (pagination) {
            dispatch(setWorkflowCurrentPage(pagination))
        } else {
            dispatch(setWorkflowCurrentPage(initPagination))
        }

        dispatch(fetchWorkflowData(response.responseBody))
    } catch (error: any) {
        showServerError(parseError(error))
        dispatch(resetWorkflowData())
    } finally {
        dispatch(setWorkflowsLoading(false))
    }
}

export const fetchWorkFlowRequestsTypes = (category: WorkflowCategory): AppThunk => async (dispatch) => {
    try {
        const response = await api_fetch_workflow_types(category)

        dispatch(fetchWorkflowTypes(response.responseBody))
    } catch (error: any) {
        showServerError(parseError(error))
    } finally {

    }
}


interface ProcessWorkflowProps {
    category: WorkflowCategory
    shouldApprove: boolean
    comment: string
    requestId: string | number
    callback: (value: any) => void
    additionalInfo?:{
        assignee: string,
        reference: string
    }
}

export const processWorkflow = ({
    category,
    shouldApprove,
    comment,
    requestId,
    callback,
    additionalInfo
}: ProcessWorkflowProps): AppThunk => async (dispatch) => {
    try {
        await api_process_workflow(category, shouldApprove, comment, requestId, additionalInfo)

        showMessage("SUCCESSFUL", "Request processed successfully")
        callback(true)
    } catch (error) {
        callback(false)
        showServerError(error)
    } finally {

    }
}

interface FecthWorkflowRequestsParams {
    category: WorkflowCategory
    params?: Record<string, any>
    pagination?: Record<string, any>
}

export const fetchPendingRequests =
    ({
        category,
        params,
        pagination
    }: FecthWorkflowRequestsParams): AppThunk => async (dispatch) => {

        dispatch(fetchWorkFlowRequests(
            PENDING_REQUESTS_URL_MAP[category],
            params,
            pagination
        ))

    }

export const fetchCompletedRequests =
    ({
        category,
        params,
        pagination
    }: FecthWorkflowRequestsParams): AppThunk => async (dispatch) => {

        dispatch(fetchWorkFlowRequests(
            COMPLETED_REQUESTS_URL_MAP[category],
            params,
            pagination
        ))

    }
