import { Modal, Typography } from "antd";
import UpdatedLoanOfferDetails from "../../modules/Dashboard/Workflow/Viewers/LoanUpdateViewer/UpdatedLoanOfferDetails";

const { Title } = Typography;

interface ConfirmLoanOfferChangesModalProps {
  visible: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  loanDetails: any;
  comparedData: any;
  loading: boolean;
  ovdFormIsActive: boolean;
  error?: any;
}

const ConfirmLoanOfferChangesModal = ({
  visible,
  onConfirm,
  onCancel,
  loanDetails,
  comparedData,
  loading,
  ovdFormIsActive,
  error,
}: ConfirmLoanOfferChangesModalProps) => {
  return (
    <Modal
      destroyOnClose={true}
      centered
      open={visible}
      title={<Title level={4}>Preview Changes</Title>}
      width={800}
      okText={ovdFormIsActive ? "Proceed to Convert" : "Proceed to Save"}
      cancelText="Cancel"
      okButtonProps={{ disabled: loading }}
      cancelButtonProps={{ disabled: loading }}
      onOk={onConfirm}
      onCancel={() => {
        if (!loading) onCancel();
      }}
      closable={!loading}

    >
      <div style={{ textAlign: "center" }}>
        <UpdatedLoanOfferDetails
          loading={loading}
          error={error}
          loanDetails={loanDetails}
          updatedOffer={comparedData}
        />
      </div>
    </Modal>
  );
};

export default ConfirmLoanOfferChangesModal;
