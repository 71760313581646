import { Tag } from "antd";
import { PmStatus } from "../types";

interface PmStatusTagProps {
    status: string;
}
export const PmStatusTag = ({ status }: PmStatusTagProps) => {
    const color = status === PmStatus.ENABLED ? "#87d068" : "#f39834";

    return  <Tag color={color} className="px-3 py-1 text-xs font-medium">{status}</Tag>;
}
