import React, { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import moment from "moment";
import {
  Card,
  Spin,
  Descriptions,
  Typography,
  Button,
  Modal,
  Tag,
  Space,
  Popconfirm,
} from "antd";
import Page, { BreadCrumbItem } from "../../../../components/Page";
import { DashboardRoutePaths } from "../../../../routes/Dashboard/routePaths";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { showMessage } from "../../../../utils/notifications";
import {
  reassignedLoanRequest,
  resetAllAssignedLoanRequestStates,
} from "../loanRequestSlice/loanRequests.thunks";
import { convertStatusTextToColor } from "../../../../utils/workflow";
import {
  LoanRequestStatus,
  ReassignType,
  OfficerType,
} from "../loanRequestSlice/loanRequest.types";
import { loanRequestStatusConverter } from "../ViewLoanRequests";
import { parseAmountToCurrencyValue } from "../../../../utils/dataParser";
import { useQueryLoanRequestDetails } from "./hooks/useQueryLoanRequestDetails";
import FCOsTable from "../../FCOManagement/ViewFCOManagers/FCOsTable";
import PmsTable from "../../PortfolioManagement/ViewPortfolioManagers/PmsTable";
import { TextAreaComponent } from "../../../../components/TextArea";
import { ArrowLeftOutlined } from "@ant-design/icons";
import LoanRequestVerificationTable from "../LoanVerificationTable";
import FCOList from "../FCOList";
import { BusinessVerificationTrigger } from "./components/businessVerificationTrigger";

const breadCrumbItems: BreadCrumbItem[] = [
  {
    title: "Dashboard",
    path: DashboardRoutePaths.home.path,
  },
  {
    title: "Loan Requests",
    path: DashboardRoutePaths.viewLoanRequests.path,
  },
  {
    title: "View Loan Request Details",

    active: true,
  },
];

const ViewLoanRequestDetails: React.FC = () => {
  const dispatch = useAppDispatch();
  const params = useParams<{ "*": string; id: string }>();
  let [searchParams] = useSearchParams();
  const {
    loanRequests: {
      loanReassignedLoading,
      loanReassignedError,
      loanReassignedSuccess,
    },
  } = useAppSelector((state) => state);

  const { loanRequestDetails, loanRequestDetailsLoading } =
    useQueryLoanRequestDetails({
      payload: { id: params?.id as string },
    });

  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string | null>(null);
  const [status, setStatus] = useState("");
  const [officerTypeState, setOfficerType] = useState<string>("");
  const [userNameToBeReAssignLoanRequest, setUserNameToBeReAssignLoanRequest] =
    useState<string>("");
  const [showLoanCommentForm, setShowLoanCommentForm] =
    useState<boolean>(false);
  const [rowValues, setRowValues] = useState<any>({});
  useEffect(() => {
    if (loanReassignedError) {
      if (
        modalTitle === ReassignType.REASSIGN_FIRST_FCO ||
        modalTitle === ReassignType.REASSIGN_SECOND_FCO
      ) {
        showMessage(
          "Reassign Field Credit Officer",
          loanReassignedError,
          "error",
        );
      } else {
        showMessage("Reassign Portfolio Manager", loanReassignedError, "error");
      }
      return () => {
        dispatch(resetAllAssignedLoanRequestStates());
      };
    }
    // eslint-disable-next-line
  }, [loanReassignedError, dispatch]);

  useEffect(() => {
    if (loanReassignedSuccess) {
      if (
        modalTitle === ReassignType.REASSIGN_FIRST_FCO ||
        modalTitle === ReassignType.REASSIGN_SECOND_FCO
      ) {
        showMessage(
          "Reassign Field Credit Officer",
          "Request is Pending approval",
          "success",
        );
      } else {
        showMessage(
          "Reassign Portfolio Manager",
          "Request is Pending approval",
          "success",
        );
      }

      dispatch(resetAllAssignedLoanRequestStates());
      closeModal();
    }
    // eslint-disable-next-line
  }, [loanReassignedSuccess, dispatch]);

  useEffect(() => {
    if (searchParams.get("status") !== null) {
      const query = searchParams.get("status");
      if (query) {
        if (LoanRequestStatus.AWAITING_ASSIGNMENTS === query) {
          setStatus("AWAITING ASSIGNMENTS");
        }

        if (LoanRequestStatus.AWAITING_FIRST_FCO_APPROVAL === query) {
          setStatus("AWAITING FIRST FCO APPROVAL");
        }
        if (LoanRequestStatus.AWAITING_SECOND_FCO_APPROVAL === query) {
          setStatus("AWAITING SECOND FCO APPROVAL");
        }
        if (LoanRequestStatus.AWAITING_PM_APPROVAL === query) {
          setStatus("AWAITING PM APPROVAL");
        }
        if (LoanRequestStatus.AWAITING_FINAL_APPROVAL === query) {
          setStatus("AWAITING FINAL APPROVAL");
        }
        if (LoanRequestStatus.APPROVED === query) {
          setStatus("APPROVED");
        }
        if (LoanRequestStatus.REJECTED === query) {
          setStatus("REJECTED");
        }
      }
    }
  }, [searchParams]);

  const OpenModal = (mode: string, officerType: string) => {
    setOfficerType(officerType);
    setModalTitle(mode);
    setShowModal(true);
    setShowLoanCommentForm(false);
  };
  const closeModal = () => {
    setShowLoanCommentForm(false);
    setShowModal(false);
  };
  const reassignLoanRequestApi = (reassignReason: string) => {
    if (rowValues) {
      setUserNameToBeReAssignLoanRequest(rowValues?.username);
      const officerCode = rowValues?.fcoCode
        ? rowValues?.fcoCode
        : rowValues?.pmCode;
      const reassignedPayload = {
        loanRequestReference: loanRequestDetails
          ? loanRequestDetails.loanRequestReference
          : "",
        officerType: officerTypeState,
        reason: reassignReason ? reassignReason : null,
        officerCode,
      };
      dispatch(reassignedLoanRequest(reassignedPayload));
      setShowLoanCommentForm(false);
    }
  };

  const reassignedLoanRequestToAnotherOfficer = (row: any) => {
    setRowValues(row);
    setShowLoanCommentForm(true);
  };

  const onBackButtonClick = () => {
    setShowLoanCommentForm(false);
  };
  const tableColumnsPmsModalView = [
    {
      title: "",
      dataIndex: "pmCode",
      key: "pmCode",
      render: (value: string, row: any) => {
        if (row?.username === loanRequestDetails?.pmUsername) {
          return (
            <Button
              type="primary"
              className="bg-[#0190fe] border-[#0190fe] rounded"
              disabled={true}
            >
              Assigned
            </Button>
          );
        } else {
          return (
            <Popconfirm
              title={
                <span>{`Are you sure you want to reassign this loan request's PM from ${loanRequestDetails?.pmUsername} to ${row?.username}?`}</span>
              }
              onConfirm={() => reassignedLoanRequestToAnotherOfficer(row)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="primary"
                className="bg-[#0190fe] border-[#0190fe] rounded"
              >
                Reassign
              </Button>
            </Popconfirm>
          );
        }
      },
    },
  ];

  const tableColumnsFCOModalView = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value: string) => {
        if (value === "ENABLED") {
          return (
            <Button
              type="primary"
              style={{ background: "#28A745", borderColor: "#28A745" }}
            >
              Enabled
            </Button>
          );
        } else {
          return (
            <Button type="primary" disabled={true}>
              Disabled
            </Button>
          );
        }
      },
    },
    {
      title: "",
      dataIndex: "fcoCode",
      key: "fcoCode",
      render: (value: string, row: any) => {
        if (
          row?.username === loanRequestDetails?.firstFcoUsername ||
          row?.username === loanRequestDetails?.secondFcoUsername
        ) {
          return (
            <Button
              type="primary"
              danger
              className="bg-[#0190fe] border-[#0190fe] rounded"
              disabled={true}
            >
              Assigned
            </Button>
          );
        } else {
          if (row?.status === "ENABLED") {
            return (
              <Popconfirm
                title={
                  <span>{`Are you sure you want to reassign this loan request's FCO from ${
                    modalTitle === ReassignType.REASSIGN_FIRST_FCO
                      ? loanRequestDetails?.firstFcoUsername
                      : loanRequestDetails?.secondFcoUsername
                  } to ${row?.username}?`}</span>
                }
                onConfirm={() => reassignedLoanRequestToAnotherOfficer(row)}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  type="primary"
                  className="bg-[#0190fe] border-[#0190fe] rounded"
                >
                  Reassign
                </Button>
              </Popconfirm>
            );
          } else {
            return (
              <Button
                type="primary"
                className="bg-[#0190fe] border-[#0190fe] rounded"
                disabled={true}
              >
                Reassign
              </Button>
            );
          }
        }
      },
    },
  ];

  const defaultFCOsSearchParams = React.useRef({ status: "ENABLED" });

  const shouldShowButton =
    loanRequestDetails?.pmUsername &&
    [
      LoanRequestStatus.AWAITING_VERIFICATIONS_AND_AGREEMENTS_DISPATCH,
      LoanRequestStatus.AWAITING_VERIFICATIONS_AND_AGREEMENTS_EXECUTION,
      LoanRequestStatus.AWAITING_FIRST_FCO_APPROVAL,
      LoanRequestStatus.AWAITING_SECOND_FCO_APPROVAL,
      LoanRequestStatus.AWAITING_PM_APPROVAL,
    ].includes(loanRequestDetails.requestStatus);

  const shouldShowReassignButton =
    loanRequestDetails?.secondFcoUsername &&
    [
      LoanRequestStatus.AWAITING_VERIFICATIONS_AND_AGREEMENTS_DISPATCH,
      LoanRequestStatus.AWAITING_VERIFICATIONS_AND_AGREEMENTS_EXECUTION,
      LoanRequestStatus.AWAITING_FIRST_FCO_APPROVAL,
      LoanRequestStatus.AWAITING_SECOND_FCO_APPROVAL,
    ].includes(loanRequestDetails?.requestStatus);

  const shouldShowReassignFirstFCOButton =
    loanRequestDetails?.firstFcoUsername &&
    [
      LoanRequestStatus.AWAITING_VERIFICATIONS_AND_AGREEMENTS_DISPATCH,
      LoanRequestStatus.AWAITING_VERIFICATIONS_AND_AGREEMENTS_EXECUTION,
      LoanRequestStatus.AWAITING_FIRST_FCO_APPROVAL,
    ].includes(loanRequestDetails?.requestStatus);

  return (
    <>
      <Page breadCrumbItems={breadCrumbItems}>
        <Card title="View Loan Request Details" style={{ margin: "18px 0" }}>
          <section>
            <section>
              {loanRequestDetailsLoading ? (
                <section className="flex items-center justify-center">
                  <Spin tip="Loading Loan Request Details..." />
                </section>
              ) : (
                <>
                  <section className="flex items-center justify-center">
                    <Descriptions layout="vertical">
                      <Descriptions.Item
                        label={
                          <Typography.Text strong>
                            Business Name
                          </Typography.Text>
                        }
                      >
                        {loanRequestDetails?.businessName
                          ? loanRequestDetails?.businessName
                          : "-----"}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={
                          <Typography.Text strong>
                            Business Owner Name
                          </Typography.Text>
                        }
                      >
                        {loanRequestDetails?.businessOwnerName
                          ? loanRequestDetails?.businessOwnerName
                          : "-----"}
                      </Descriptions.Item>

                      <Descriptions.Item
                        label={
                          <Typography.Text strong>
                            Date Requested
                          </Typography.Text>
                        }
                      >
                        {loanRequestDetails?.dateRequested
                          ? moment(loanRequestDetails?.dateRequested).format(
                              "YYYY-MM-DD",
                            )
                          : "-----"}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={
                          <Typography.Text strong>
                            First FCO User Name
                          </Typography.Text>
                        }
                      >
                        {loanRequestDetails?.firstFcoUsername
                          ? loanRequestDetails?.firstFcoUsername
                          : "-----"}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={
                          <Typography.Text strong>
                            Second FCO User Name
                          </Typography.Text>
                        }
                      >
                        {loanRequestDetails?.secondFcoUsername
                          ? loanRequestDetails?.secondFcoUsername
                          : "-----"}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={
                          <Typography.Text strong>
                            Portfolio Manager
                          </Typography.Text>
                        }
                      >
                        {loanRequestDetails?.pmUsername
                          ? loanRequestDetails?.pmUsername
                          : "-----"}
                      </Descriptions.Item>

                      <Descriptions.Item
                        label={
                          <Typography.Text strong>Loan Amount</Typography.Text>
                        }
                      >
                        {loanRequestDetails?.loanAmount
                          ? parseAmountToCurrencyValue(
                              loanRequestDetails?.loanAmount,
                              "₦",
                            )
                          : "-----"}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={
                          <Typography.Text strong>Request Ref</Typography.Text>
                        }
                      >
                        {loanRequestDetails?.loanRequestReference
                          ? loanRequestDetails?.loanRequestReference
                          : "-----"}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={
                          <Typography.Text strong>
                            Request Status
                          </Typography.Text>
                        }
                      >
                        <Tag
                          color={convertStatusTextToColor(status)}
                          className="whitespace-break-spaces"
                          style={{ whiteSpace: "break-spaces" }}
                        >
                          {loanRequestStatusConverter(
                            loanRequestDetails?.requestStatus,
                          )}
                        </Tag>
                      </Descriptions.Item>
                    </Descriptions>
                  </section>

                  <div className="flex flex-wrap mt-8 space-x-4">
                    {shouldShowReassignFirstFCOButton && (
                      <Button
                        type="primary"
                        className="rounded"
                        onClick={() =>
                          OpenModal(
                            ReassignType.REASSIGN_FIRST_FCO,
                            OfficerType.FIRST_FCO,
                          )
                        }
                      >
                        Reassign First FCO
                      </Button>
                    )}

                    {shouldShowReassignButton && (
                      <Button
                        type="primary"
                        className="rounded"
                        onClick={() =>
                          OpenModal(
                            ReassignType.REASSIGN_SECOND_FCO,
                            OfficerType.SECOND_FCO,
                          )
                        }
                      >
                        Reassign Second FCO
                      </Button>
                    )}

                    {shouldShowButton ? (
                      <Button
                        type="primary"
                        className="rounded"
                        onClick={() =>
                          OpenModal(ReassignType.REASSIGN_PM, OfficerType.PMO)
                        }
                      >
                        Reassign Portfolio Manager
                      </Button>
                    ) : null}

                    {loanRequestDetails?.canTriggerBusinessVerification && (
                      <BusinessVerificationTrigger
                        loanRequestRef={
                          loanRequestDetails?.loanRequestReference
                        }
                      />
                    )}
                  </div>
                </>
              )}
            </section>
          </section>
        </Card>
        <Card title="Loan Request Verifications" style={{ margin: "18px 0" }}>
          <LoanRequestVerificationTable
            data={loanRequestDetails?.fieldVerificationRequestDetails}
            loanRequestReference={loanRequestDetails?.loanRequestReference}
          />
        </Card>

        {loanRequestDetails?.requestStatus ===
          LoanRequestStatus.AWAITING_ASSIGNMENTS && (
          <Card
            title="Field Credit Officers In Loan LGA"
            style={{ margin: "18px 0" }}
          >
            <FCOList loanRequestReference={params?.id as string} />
          </Card>
        )}
      </Page>
      <Modal
        destroyOnClose={true}
        centered
        open={showModal}
        onCancel={() => closeModal()}
        footer={null}
        width={!loanReassignedLoading ? 850 : 500}
        closable={!loanReassignedLoading ? true : false}
        maskClosable={!loanReassignedLoading ? true : false}
        title={
          !loanReassignedLoading ? (
            <Typography.Title level={5}>{modalTitle}</Typography.Title>
          ) : null
        }
      >
        {showLoanCommentForm ? (
          <>
            <TextAreaComponent
              callBackAction={reassignLoanRequestApi}
              showCounter={true}
              isTextFieldCompulsory={true}
            >
              <Button
                type="link"
                size="large"
                onClick={onBackButtonClick}
                icon={<ArrowLeftOutlined />}
                className="pt-[10px]"
              >
                Back
              </Button>
            </TextAreaComponent>
          </>
        ) : !loanReassignedLoading ? (
          <>
            {modalTitle === ReassignType.REASSIGN_FIRST_FCO ||
            modalTitle === ReassignType.REASSIGN_SECOND_FCO ? (
              <FCOsTable
                extraColumns={tableColumnsFCOModalView}
                fixedSearchParams={defaultFCOsSearchParams.current}
              />
            ) : null}
            {modalTitle === ReassignType.REASSIGN_PM && (
              <PmsTable extraColumns={tableColumnsPmsModalView} />
            )}
          </>
        ) : (
          <>
            <Space
              direction="vertical"
              style={{ width: "100%", height: "200px" }}
            >
              <Spin tip="Loading" size="large" className="my-[30px]">
                <div className="content" />
              </Spin>

              <div className="my-[80px] flex justify-center">
                {" "}
                {modalTitle === ReassignType.REASSIGN_FIRST_FCO ? (
                  <span>
                    {`Reassigning this loan request's FCO from ${loanRequestDetails?.firstFcoUsername} to
                    ${userNameToBeReAssignLoanRequest}`}
                  </span>
                ) : null}
                {modalTitle === ReassignType.REASSIGN_SECOND_FCO ? (
                  <span>
                    {`Reassigning this loan request's FCO from ${loanRequestDetails?.secondFcoUsername} to
                    ${userNameToBeReAssignLoanRequest}`}
                  </span>
                ) : null}
                {modalTitle === ReassignType.REASSIGN_PM && (
                  <span>
                    {`Reassigning this loan request's PM from ${loanRequestDetails?.pmUsername} to
                 ${userNameToBeReAssignLoanRequest}`}
                  </span>
                )}
              </div>
            </Space>
          </>
        )}
      </Modal>
    </>
  );
};

export default ViewLoanRequestDetails;
