import { FC } from 'react';
import { Button, Popconfirm, Typography } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { demotePM } from '../services/demotePM';

const Title = Typography.Title;
interface PmDemotionWidgetProps {
    pmCode: string;
}

export const PmDemotionWidget: FC<PmDemotionWidgetProps> = ({
    pmCode
}) => {
    const onConfirm = async () => {
        return demotePM(pmCode)
    }

    return (
        <Popconfirm
            placement='topLeft'
            title={<PopTitle />}
            onConfirm={onConfirm}
            okText="Yes, Demote PM"
            okButtonProps={{
                className: 'h-10 min-w-[100px] px-4 py-2 mt-6 rounded'
            }}
            cancelButtonProps={{
                className: 'h-10 min-w-[100px] px-4 py-2 mt-6 rounded'
            }}
            icon={<QuestionCircleOutlined className='text-orange-400 mb-3' />}
        >
            <Button
                type="primary"
                className="min-w-[150px] rounded"
            >
                Demote PM to FCO
            </Button>
        </Popconfirm>
    );
}

const PopTitle = () => (
    <Title level={5}>
        Are you sure you want to demote this PM to an FCO?
    </Title>
);
