import { Buffer } from 'buffer';
import { AppThunk } from '../../../../../store';
import { get_file_service } from '../fileApi';
import { parseError } from "../../../../../utils/server";
import {fromBuffer} from "file-type/browser";
import {RcFile} from "antd/es/upload/interface";

// @ts-ignore
window.Buffer = Buffer;

export const fetchFileUrl =
    (
        fileRef: string,
        callback?: (url: string, error?: any) => void
    ): AppThunk =>
        async (dispatch) => {
          try {
            const response = await get_file_service(fileRef);

            if (response) {
              const buffer = Buffer.from(response, "binary");
              // Detect MIME type from the file content
              const fileTypeResult = await fromBuffer(buffer);
              const mimeType = fileTypeResult?.mime || "application/octet-stream"; // Default if undetectable
              // Convert to Base64 URL
              const base64File = buffer.toString("base64");
              const fileUrl = `data:${mimeType};base64,${base64File}`;
              if (callback) {
                callback(fileUrl);
              }
            }
          } catch (error: any) {
            if (callback) {
              callback("", parseError(error));
            }
          }
        };


export const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = error => reject(error);
    });
