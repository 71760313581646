import React, { useEffect, useState } from "react";
import { Image, Avatar, Spin } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useAppDispatch } from "../../store";
import { fetchFileUrl } from "../../modules/Dashboard/Utils/FilesUtils/fileSlice/fileSlice.thunk";

interface RefedImageProps {
  fileRef: string;
  width?: number;
  alt?: string;
  className?: string;
}

const RefedImage: React.FC<RefedImageProps> = ({
  fileRef,
  width,
  alt,
  className,
}) => {
  const dispatch = useAppDispatch();
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    let isMounted = true;

    dispatch(
      fetchFileUrl(fileRef, (url) => {
        if (isMounted) {
          setImageUrl(url);
          setLoading(false);
        }
      }),
    );

    return () => {
      isMounted = false;
    };
  }, [fileRef, dispatch]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <Spin tip="Loading image..." />
      </div>
    );
  }

  if (imageUrl) {
    return (
      <Image width={width} src={imageUrl} alt={alt} className={className} />
    );
  } else {
    return <Avatar shape="square" size={width} icon={<UserOutlined />} />;
  }
};

export default RefedImage;
