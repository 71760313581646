import { notification } from "antd";
import { parseError } from "./server";

type NotificationType = "success" | "info" | "warning" | "error";

export const showServerError = (error: any) => {
  const errorMessage = parseError(error);
  showMessage("Request Failed", errorMessage, "error");
};

export const showError = (error: any) => {
  const errorMessage = parseError(error);
  showMessage("Error!", errorMessage, "error");
};

export const showMessage = (
  title: string,
  description: string,
  type: NotificationType = "success",
) => {
  notification.open({
    type: type,
    message: title,
    description: description,
  });
};

export const SUCCESS_MESSAGES = {
  EDIT_LOAN_OFFER: {
    title: "Request Successful",
    // message:
    //   "Your update request has been successfully submitted. The changes will be applied upon approval.",
    message:
      "Your offer update request was successful! New details should load soon. If they aren’t visible right away, try refreshing the page.",
  },
  GRANT_LOAN_ACCESS: {
    title: "Request Successful",
    message:
      "Loan access request submitted successfully. Access will be granted upon approval.",
  },
  CONVERT_WCL_TO_OVD: {
    title: "Request Successful",
    // message:
    //   "Request to convert WCL offer to OVD submitted successfully. Conversion will occur after approval.",
    message: "Your WCL offer has been successfully converted to an overdraft! New details should load soon. If they aren’t visible right away, try refreshing the page."
  },
};
