import { ILocalGovernment } from "../../../Utils/UtilsApi/utilsAPI.types";
import { useMemo } from "react";
import { ELoanContractProduct } from "../../../LoanContractsManagement/utils/types";

export const mapLGACodeToName = (
  code: string | undefined,
  LGAs: ILocalGovernment[],
) => {
  if (!code) {
    return "N/A";
  }
  return LGAs.find((lga) => lga.code === code)?.name || "N/A";
};

const isWorkingCapitalProduct = (product: string) =>
  product === ELoanContractProduct.WORKING_CAPITAL;

const isOverdraftProduct = (product: string) =>
  product === ELoanContractProduct.OVERDRAFT;

export const canShowRepaymentFrequency = (
  isOVDConversion: boolean,
  formState: any,
  loanDetails: { loanProduct: string },
) => {
  if (!loanDetails || isOVDConversion) return false;

  const loanProduct = !formState?.editMode ?
      loanDetails.loanProduct :
      formState?.editedData?.loanProduct;

  return isWorkingCapitalProduct(loanProduct);

};
