export const labels: { [key: string]: string } = {
  amount: "Amount",
  tenure: "Tenure",
  loanTenureUnit: "Loan Tenure Unit",
  processingFee: "Processing Fee",
  managementFee: "Management Fee",
  hourlyInterestRateInPercentage: "Hourly Interest Rate",
  dailyInterestRateInPercentage: "Daily Interest Rate",
  repaymentFrequency: "Repayment Frequency",
  regularizationPeriod: "Regularization Period",
  maxDefaultCountToTermination: "Maximum Default Count To Termination",
  managementFeeDiscountInPercentage: "Management Fee Discount In Percentage",
};
