import React, { useState } from "react";
import { get_loan__approval_details_service } from "../API";
import { showMessage } from "../../../../../utils/notifications";
import { parseError } from "../../../../../utils/server";
import { ILoanRequestApprovalDetails } from "../utils/types";

interface UseLoanApprovalDetailsOptions {
  loanReference: string | null;
}

export const useLoanApprovalDetails = ({
  loanReference,
}:UseLoanApprovalDetailsOptions) => {
  const [loading, setLoading] = React.useState(false);
  const [loanRequestApproval, setLoanRequestApproval] = useState<
    ILoanRequestApprovalDetails | undefined
  >();
  const [error, setError] = React.useState("");
  const [permissionError, setPermissionError] = React.useState<boolean | null>(
    false,
  );

  const getLoanApprovalDetails = React.useCallback(async () => {
    if (!loanReference) return;
    try {
      setLoading(true);
      setError("");
      setPermissionError(false);
      const response = await get_loan__approval_details_service(
        loanReference || "",
      );
      setLoanRequestApproval(response);
    } catch (error: any) {
      if (error.response && error.response.status === 403) {
        setPermissionError(true);
        return; //exit early
      }
      showMessage("Loan Request Approval", parseError(error), "error");
      setError(parseError(error));
    } finally {
      setLoading(false);
    }
  }, [loanReference]);

  return {
    loanRequestApproval,
    getLoanApprovalDetails,
    loading,
    permissionError,
    error,
  };
};
