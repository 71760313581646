import React, { useState } from "react";
import { get_silent_verification_request } from "../API";
import { showServerError } from "../../../../utils/notifications";
import {ISilentVerificationRecord} from "../../LoanRequestsManagement/AllLoanRequests/utils/types";

interface Params {
  ref: string;
}

const useQuerySilentVerificationRequestDetails = ({ ref }: Params) => {
  const [isLoadingRequestDetails, setIsLoadingRequestDetails] =
    React.useState(false);
  const [requestDetails, setRequestDetails] = useState<ISilentVerificationRecord | undefined>();

  const handleFetchRequestDetails = async () => {
    try {
      setIsLoadingRequestDetails(true);
      const response = await get_silent_verification_request(ref);
      setRequestDetails(response);
    } catch (error: any) {
      showServerError(error);
    } finally {
      setIsLoadingRequestDetails(false);
    }
  };

  React.useEffect(() => {
    handleFetchRequestDetails();
  }, [ref]);

  return {
    isLoadingRequestDetails,
    requestDetails,
  };
};

export default useQuerySilentVerificationRequestDetails;
