import React from "react";
import { create_silent_verification } from "../API";
import { showMessage, showServerError } from "../../../../utils/notifications";

interface Params {
  payload: {
    businessName: string;
    businessCategory: string;
    verificationAddress: {
      addressLineOne: string;
      addressLineTwo: string;
      city: string;
      lga: string;
    };
    businessImages: string[];
  };
  callback: () => void;
}

const useCreateSilentVerification = () => {
  const [isLoading, setIsLoading] = React.useState(false);

  const createSilentVerification = async ({ payload, callback }: Params) => {
    try {
      setIsLoading(true);

      const response = await create_silent_verification(payload);

      if (response) {
        callback();
        showMessage("Successful", "Request has been sent for approval.");
      }
    } catch (error) {
      showServerError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    createSilentVerification,
  };
};

export default useCreateSilentVerification;
