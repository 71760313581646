import React, { useState } from "react";
import Page, { BreadCrumbItem } from "../../../../../components/Page";
import { DashboardRoutePaths } from "../../../../../routes/Dashboard/routePaths";
import {
  Alert,
  Button,
  Card,
  Col,
  Descriptions,
  Form,
  Input,
  Row,
  Typography,
} from "antd";
import {
  enable_loan_for_business_owner_service,
  get_loan_eligibility_service,
} from "../API";
import { parseError } from "../../../../../utils/server";
import { ILoanEligibilityResponse } from "../utils/types";
import {
  showMessage,
  SUCCESS_MESSAGES,
} from "../../../../../utils/notifications";
import EnableLoanConfirmationModal from "../../../../../components/Modals/EnableLoanConfirmationModal";

const { Text } = Typography;

const breadCrumbItems: BreadCrumbItem[] = [
  {
    title: "Dashboard",
    path: DashboardRoutePaths.home.path,
  },
  {
    title: "Enable Loan",
    active: true,
  },
];

const EnableLoan: React.FC = () => {
  const [form] = Form.useForm();
  const [eligibility, setEligibility] =
    useState<ILoanEligibilityResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [isEnablingLoan, setIsEnablingLoan] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const onClearForm = () => {
    form.resetFields();
    setEligibility(null);
  };

  // Simulate an API call with setTimeout
  const fetchLoanEligibility = async () => {
    setLoading(true);
    try {
      const response = await get_loan_eligibility_service(
        form.getFieldValue("businessAccountNumber"),
      );

      setEligibility(response);
    } catch (err) {
      showMessage("Error", parseError(err), "error");
    } finally {
      setLoading(false);
    }
  };

  const handleEnableLoan = async () => {
    setShowConfirmation(false);
    setIsEnablingLoan(true);
    try {
      await enable_loan_for_business_owner_service(
        eligibility?.businessOwnerCode ?? "",
      );
      setEligibility(null);
      form.resetFields();
      showMessage(
        SUCCESS_MESSAGES.GRANT_LOAN_ACCESS.title,
        SUCCESS_MESSAGES.GRANT_LOAN_ACCESS.message,
      );
    } catch (err) {
      showMessage("Error", parseError(err), "error");
    } finally {
      setIsEnablingLoan(false);
    }
  };

  return (
    <Page breadCrumbItems={breadCrumbItems}>
      <Card className="my-[18px]" bordered={false}>
        <Form
          layout="vertical"
          name="filter-fco"
          form={form}
          onFinish={fetchLoanEligibility}
        >
          <Col xs={24} sm={12} md={8}>
            <Form.Item
              label="Business Nuban Account Number"
              name="businessAccountNumber"
              rules={[
                {
                  required: true,
                  message: "Please enter the Business Nuban Account Number",
                },
                {
                  pattern: /^\d{10}$/,
                  message: "Account number must be exactly 10 digits",
                },
              ]}
            >
              <Input size="large" placeholder="Enter account number" />
            </Form.Item>
          </Col>
          <Row gutter={[12, 12]}>
            <Col xs={24} sm={12} md={3}>
              <Button
                type="primary"
                className="w-full rounded"
                danger
                onClick={onClearForm}
              >
                Clear
              </Button>
            </Col>
            <Col xs={24} sm={12} md={4}>
              <Button
                type="primary"
                className="w-full bg-[#0190fe] border-[#0190fe] rounded"
                htmlType="submit"
                loading={loading}
              >
                Search
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
      {eligibility && (
        <Card title="Business Details" className="my-[18px] w-full">
          {eligibility?.hasPendingLoanAccessRequest && (
            <div className="ant-col-md-12 mb-4">
              <Alert
                message="The business owner's loan access request is awaiting approval"
                type="warning"
                showIcon
              />
            </div>
          )}
          <Descriptions layout="vertical" contentStyle={{ marginTop: "-16px" }}>
            <Descriptions.Item label={<Text strong>Business Owner Name</Text>}>
              {eligibility.businessOwnerName || "N/A"}
            </Descriptions.Item>

            <Descriptions.Item label={<Text strong>Loan Access</Text>}>
              <span
                className={
                  eligibility?.hasAccessToLoans
                    ? "text-alert-success"
                    : "text-alert-danger"
                }
              >
                {eligibility?.hasAccessToLoans ? "Yes" : "No"}
              </span>
            </Descriptions.Item>
          </Descriptions>

          <Button
            type="primary"
            className="rounded mr-[10px] mt-[10px] mb-[10px]"
            onClick={() => setShowConfirmation(true)}
            loading={isEnablingLoan}
            disabled={
              eligibility?.hasAccessToLoans ||
              eligibility?.hasPendingLoanAccessRequest
            }
          >
            Enable Loan
          </Button>

          <EnableLoanConfirmationModal
            visible={showConfirmation}
            onConfirm={handleEnableLoan}
            onCancel={() => setShowConfirmation(false)}
          />
        </Card>
      )}
    </Page>
  );
};

export default EnableLoan;
