import React from "react";
import { Form, Input, Modal, Typography } from "antd";
import { showMessage } from "../../utils/notifications";
import useTerminateWaivePeriod from "../../modules/Dashboard/LoanContractsManagement/hooks/useTerminateWaivePeriod";
import useQueryPendingTerminationRequest from "../../modules/Dashboard/LoanContractsManagement/hooks/useQueryPendingTerminationRequest";

const { Title } = Typography;
const { TextArea } = Input;

interface TerminateWaivePeriodModalProps {
  onClose: () => void;
  contractRef: string;
  selectedWavePeriodId: number | undefined;
}

const TerminateWaivePeriodModal = ({
  onClose,
  contractRef,
  selectedWavePeriodId,
}: TerminateWaivePeriodModalProps) => {
  const { isLoading, terminateWaivePeriod } = useTerminateWaivePeriod();

  const { checkPendingTerminationRequest } = useQueryPendingTerminationRequest({
    ref: contractRef,
  });

  const [form] = Form.useForm();

  const handleTerminateInterestWaiver = () => {
    form.validateFields().then(async () => {
      await terminateWaivePeriod({
        payload: {
          contractRef: contractRef,
          comment: form.getFieldValue("comments"),
          waivePeriodId: String(selectedWavePeriodId),
        },
        callback: () => {
          showMessage("Successful", "Request has been sent for approval.");
          checkPendingTerminationRequest();
          form.resetFields();
          onClose();
        },
      });
    }).catch(() => {});
  };

  return (
    <div>
      <Modal
        destroyOnClose={true}
        centered
        open={true}
        title={<Title level={4}>Terminate Waive Period</Title>}
        width={800}
        okText="Confirm"
        confirmLoading={isLoading}
        onOk={handleTerminateInterestWaiver}
        onCancel={() => onClose()}
      >
        <Form
          form={form}
          layout="vertical"
          name="terminate_wave_period_form"
          initialValues={{ comments: "" }}
          size="large"
        >
          <Form.Item
            name="comments"
            label="Comments"
            hasFeedback
            rules={[
              {
                required: true,
                message:
                  "Please provide a reason for terminating this waive period.",
              },
              { min: 6 },
              { max: 255 },
            ]}

          >
            <TextArea rows={4} showCount maxLength={255} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default TerminateWaivePeriodModal;
