import React, { FC, useMemo, useState } from "react";
import { Modal, Tag, Button, Form, Input, Divider, Card } from "antd";
import { DoubleRightOutlined } from "@ant-design/icons";
import Moment from "react-moment";
import { getViewer } from "./viewer-config";
import Optional from "../../../../../components/Optional";
import { convertStatusTextToColor } from "../../../../../utils/workflow";
import { Loading } from "../../../../../components/Loaders";
import { useAppDispatch } from "../../../../../store";
import { processWorkflow } from "../../workflowSlice/workflowSlice.thunks";
import { WorkflowCategory } from "../../workflowSlice/workflowSlice.types";
import { WorkflowShouldValidateComments } from "../../utils/types";
import {maxCommentLength, minCommentLength} from "../../../LoanRequestsManagement/utils/constants";

const FormItem = Form.Item;

interface RequestViewerProps {
  category: WorkflowCategory;
  data: any;
  enableActions: any;
  onRejected?(): void;
  onApproved?(): void;
  visible?: boolean;
  onCancel?(): void;
}

const RequestViewer: FC<RequestViewerProps> = ({
  data,
  enableActions,
  onRejected,
  onApproved,
  onCancel,
  visible,
  category,
}) => {
  const json = useMemo(() => {
    let json = {};
    try {
      if (data) {
        json = JSON.parse(data.actionParameters);
      }
    } catch (error) {
    } finally {
      return json;
    }
  }, [data]);

  const Viewer = useMemo(() => getViewer(data), [data]);

  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const [state, setState] = useState<{
    approveButtonDisabled?: boolean;
    rejectButtonDisabled?: boolean;
    approveButtonLoading?: boolean;
    rejectButtonLoading?: boolean;
    canApproveRequest?: boolean;
  }>({
    approveButtonDisabled: false,
    rejectButtonDisabled: false,
    approveButtonLoading: false,
    rejectButtonLoading: false,
    canApproveRequest: true,
  });

  const handleRejected = () => {
    onRejected && onRejected();
    form.resetFields();
  };
  const handleApproved = () => {
    onApproved && onApproved();
    form.resetFields();
  };
  const handleCancle = () => {
    onCancel && onCancel();
    form.resetFields();
  };


 const validateForm = async (
  onSuccess: (value: any) => void,
  approval: boolean
) => {
  const shouldValidateComments =
    WorkflowShouldValidateComments[category];
  if (approval && !shouldValidateComments) {
    const comments = form.getFieldValue("comments")
      ? form.getFieldValue("comments")
      : "Approved";
    onSuccess({ comments });
    return;
  }
  try {
    const fieldsToValidate = ["comments"];
    if (data?.workflowRequestType === "SILENT_BUSINESS_VERIFICATION") {
      fieldsToValidate.push("assignee");
    }
    const values = await form.validateFields(fieldsToValidate);
    onSuccess(values);
  } catch (error) {}
};

const onProcessWorkflowRequest = (
  values: any,
  shouldApprove: boolean,
  callback: (value?: any) => void,
) => {
  const requestPayload: any = {
    category,
    shouldApprove,
    comment: values.comments,
    requestId: data.reference,
    callback,
  };

  if (data?.workflowRequestType === "SILENT_BUSINESS_VERIFICATION") {
    requestPayload.additionalInfo = {
      assignee: form.getFieldValue("assignee"),
      reference: data.reference,
    };
  }

  dispatch(processWorkflow(requestPayload));
};



  const reject = () => {
    validateForm((values) => {
      setState((prev) => ({
        ...prev,
        approveButtonDisabled: true,
        rejectButtonLoading: true,
      }));

      onProcessWorkflowRequest(values, false, (processed) => {
        setState((prev) => ({
          ...prev,
          approveButtonDisabled: false,
          rejectButtonLoading: false,
        }));
        if (processed) {
          handleRejected();
        }
      });
    }, false);
  };

  const approve = () =>
    validateForm((values) => {
      const comments = values.comments;
      setState((prev) => ({
        ...prev,
        rejectButtonDisabled: true,
        approveButtonLoading: true,
      }));

      onProcessWorkflowRequest({ comments }, true, (processed) => {
        setState((prev) => ({
          ...prev,
          rejectButtonDisabled: false,
          approveButtonLoading: false,
        }));
        if (processed) {
          handleApproved();
        }
      });
    }, true);

  const toggleCanApproveRequest = (canApproveRequest: boolean) => {
    setState((prev) => ({
      ...prev,
      canApproveRequest,
    }));
  };

  const description = data?.description || "Approve";
  const requestStatus = data?.requestStatus || "PENDING";

  return (
    <>
      <Modal
        destroyOnClose={true}
        centered
        className="modal-lg my-6 px-2"
        width={850}
        open={visible}
        onCancel={handleCancle}
        footer={
          enableActions && state.canApproveRequest ? (
            <div className="py-4">
              <Button
                loading={state.rejectButtonLoading}
                disabled={state.rejectButtonDisabled}
                onClick={reject}
                danger
                type="primary"
              >
                Reject
              </Button>
              <Button
                loading={state.approveButtonLoading}
                disabled={state.approveButtonDisabled}
                onClick={approve}
                type="primary"
                className="primary-btn-base"
              >
                Approve
              </Button>
            </div>
          ) : null
        }
        title={
          <div>
            {description} -{" "}
            <Tag color={convertStatusTextToColor(requestStatus)}>
              {requestStatus}
            </Tag>
          </div>
        }
        okText="Approve"
      >
        <div>
          <section className="card">
            <Card
              title={
                <strong>
                  Request{" "}
                  <DoubleRightOutlined
                    rotate={90}
                    style={{ paddingLeft: "5px" }}
                  />
                </strong>
              }
              type="inner"
              bodyStyle={{ padding: "0" }}
            >
              <div className="p-[25px]">
                <React.Suspense fallback={<Loading />}>
                  <Viewer
                    data={data}
                    src={json}
                    form={form}
                    toggleCanApproveRequest={toggleCanApproveRequest}
                    canApproveRequest={state.canApproveRequest}
                  />
                </React.Suspense>
              </div>
              <Divider />
              <div className="p-[25px]">
                <dl>
                  <dt>Change requested by</dt>
                  <dd>
                    <Tag color={"#0887c9"}>{data?.initiator}</Tag> on{" "}
                    <Moment date={data?.createdOn} />
                  </dd>
                </dl>
                <Optional visible={enableActions}>
                  <Form form={form}>
                    <Divider>Authorizer's Comment</Divider>
                    <FormItem
                      label="Comments"
                      name="comments"
                      labelCol={{ span: 5 }}
                      wrapperCol={{ span: 16 }}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Comment must be at least 6 characters",
                        },
                        { min: minCommentLength },
                        { max: maxCommentLength },
                      ]}
                    >
                      <Input.TextArea
                        placeholder="Please enter your comments"
                        autoSize
                      />
                    </FormItem>
                    {data?.workflowRequestType === "SILENT_BUSINESS_VERIFICATION" &&
                      <Form.Item
                        labelCol={{ span: 5 }}
                        wrapperCol={{ span: 16 }}
                        name="assignee"
                        label="Assignee Email"
                        rules={[
                          {
                            required: true,
                            message: "Please enter the assignee's email",
                          },
                          {
                            type: "email",
                            message: "Please enter a valid email address",
                          },
                        ]}
                      >
                        <Input placeholder="Enter assignee email" />
                      </Form.Item>
                    }
                  </Form>
                </Optional>
              </div>
            </Card>
          </section>
        </div>
      </Modal>
    </>
  );
};

export default RequestViewer;
