import { FC } from 'react';
import { Button, Popconfirm, Typography } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { deactivatePM } from '../services/deactivatePM';

const Title = Typography.Title;
interface PmDeactivationWidgetProps {
    pmCode: string;
}

export const PmDeactivationWidget: FC<PmDeactivationWidgetProps> = ({
    pmCode
}) => {
    const onConfirm = async () => {
        return deactivatePM(pmCode)
    }

    return (
        <Popconfirm
            placement='topLeft'
            title={<PopTitle />}
            onConfirm={onConfirm}
            okText="Yes, Deactivate PM"
            okButtonProps={{
                className: 'h-10 min-w-[100px] px-4 py-2 mt-6 rounded'
            }}
            cancelButtonProps={{
                className: 'h-10 min-w-[100px] px-4 py-2 mt-6 rounded'
            }}
            icon={<QuestionCircleOutlined className='text-orange-400 mb-3' />}
        >
            <Button
                type="primary"
                className="min-w-[150px] rounded"
            >
                Deactivate PM
            </Button>
        </Popconfirm>
    );
}

const PopTitle = () => (
    <Title level={5}>
        Are you sure you want to deactivate this PM?
    </Title>
);
