import React, { FC } from "react";
import { Descriptions, Divider, Tag, Typography } from "antd";
import { parseAmountToCurrencyValue } from "../../../../../utils/dataParser";
import { loanContractProductTypeMap } from "../../../LoanContractsManagement/utils/dataMaps";
import { ELoanContractProduct } from "../../../LoanContractsManagement/utils/types";

interface TerminateInterestWaivePeriodRequest {
  contractRef: string;
  waivePeriodId: number;
  comment: string;
}

interface ClientContractDetails {
  businessName: string;
  businessOwnerName: string;
  contractRef: string;
  contractState: string;
  contractStatus: string;
  loanAmount: number;
  loanProduct: string;
  loanRequestRef: string;
}

interface Props {
  src: {
    terminateInterestWaivePeriodRequest: TerminateInterestWaivePeriodRequest;
    clientContractDetails: ClientContractDetails;
  };
}

const TerminateInterestWaivePeriodViewer: FC<Props> = ({ src }) => {
  return (
    <div className="p-[25px]">
      <Descriptions layout="vertical" title="Waive Period Termination Details">
        <Descriptions.Item
          label={<Typography.Text strong>Contract Reference</Typography.Text>}
        >
          {src.terminateInterestWaivePeriodRequest.contractRef}
        </Descriptions.Item>
        <Descriptions.Item
          label={<Typography.Text strong>Waive Period ID</Typography.Text>}
        >
          {src.terminateInterestWaivePeriodRequest.waivePeriodId}
        </Descriptions.Item>
        <Descriptions.Item
          label={<Typography.Text strong>Comment</Typography.Text>}
        >
          {src.terminateInterestWaivePeriodRequest.comment}
        </Descriptions.Item>
      </Descriptions>
      <Divider />
      <Descriptions layout="vertical" title="Business Details">
        <Descriptions.Item
          label={<Typography.Text strong>Business Name</Typography.Text>}
        >
          {src.clientContractDetails.businessName}
        </Descriptions.Item>
        <Descriptions.Item
          label={<Typography.Text strong>Business Owner Name</Typography.Text>}
        >
          {src.clientContractDetails.businessOwnerName}
        </Descriptions.Item>
        <Descriptions.Item
          label={<Typography.Text strong>Loan Amount</Typography.Text>}
        >
          {parseAmountToCurrencyValue(
            src.clientContractDetails.loanAmount,
            "₦",
          )}
        </Descriptions.Item>
        <Descriptions.Item
          label={<Typography.Text strong>Loan Product</Typography.Text>}
        >
          <Tag
            color={
              loanContractProductTypeMap[
                src.clientContractDetails.loanProduct as ELoanContractProduct
              ]?.color
            }
          >
            {
              loanContractProductTypeMap[
                src.clientContractDetails.loanProduct as ELoanContractProduct
              ]?.text
            }
          </Tag>
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default TerminateInterestWaivePeriodViewer;
