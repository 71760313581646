import React, { useEffect, useState } from "react";
import useQueryLoanRequest from "../../../LoanRequestsManagement/AllLoanRequests/hooks/useQueryLoanRequest";
import { Alert, Descriptions, Divider, Image, Spin, Typography } from "antd";
import CustomDescriptionItem from "../../../LoanRequestsManagement/AllLoanRequests/components/loanDetails/LoanOfferItem";
import { useFileRef } from "../../../Utils/FilesUtils/hooks/useFileRef";
import {useAppDispatch, useAppSelector} from "../../../../../store";
import {fetchLGAs} from "../../../Utils/UtilsSlice/utilsSlice.thunks";
import {mapLGACodeToName} from "../../../LoanRequestsManagement/AllLoanRequests/utils";

const { Text } = Typography;

interface ActionParameters {
  request: {
    loanRequestReference: string;
    bvn: string;
    updateRequest: {
      email: string;
      phoneNumber: string | null;
      residentialAddress: {
        line1: string;
        line2: string;
        city: string;
        lgaCode: string;
      };
    };
    authorizationProof: string;
  };
  preModification: {
    verificationStatus: string;
    email: string;
    phoneNo: string;
    bvn: string;
    residentialAddress: {
      line1: string;
      line2: string;
      city: string;
      lgaCode: string;
    };
  };
}

interface Props {
  src: ActionParameters;
}

interface Address  { line1: string; line2: string; city: string; lgaCode: string }

const GuarantorUpdateViewer = ({ src }: Props) => {
  const {
    utils: { LGAs },
  } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const {
    loading: loanDetailsLoading,
    getLoanDetails,
    loanRequestDetails,
  } = useQueryLoanRequest({
    loanReference: src?.request?.loanRequestReference,
  });

  const [fileUrl, setFileUrl] = useState<string | null>(null);

  const { image, error: fileError } = useFileRef(
    src.request.authorizationProof,
  );

  useEffect(() => {
    if (src?.request?.loanRequestReference) {
      getLoanDetails();
    }
  }, [src?.request?.loanRequestReference]);

  useEffect(() => {
    if (image) {
      setFileUrl(image);
    }
  }, [image]);

  useEffect(() => {
    if (!LGAs.length) {
      dispatch(fetchLGAs());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const addressesAreEqual = (a: any, b: any) => {
    return (
      a.line1 === b.line1 &&
      a.line2 === b.line2 &&
      a.city === b.city &&
      a.lgaCode === b.lgaCode
    );
  };
  const isEmailUnchanged = src?.request?.updateRequest?.email === src?.preModification?.email;

  const isPhoneNumberUnchanged = src?.request?.updateRequest?.phoneNumber === src?.preModification?.phoneNo;

  const formatAddress = (address: Address) => {
    return `${address.line1}, ${address.line2}, ${address.city}, ${mapLGACodeToName(address.lgaCode, LGAs) || "N/A"}`;
  };

  return (
    <div className="p-[25px]">
      {loanDetailsLoading ? (
        <section className="flex items-center justify-center">
          <Spin tip="Loading Details..." />
        </section>
      ) : (
        <>
          <Descriptions
            layout="vertical"
            column={1}
            contentStyle={{ marginTop: "-16px", maxWidth: "fit-content" }}
          >
            <Descriptions.Item label={<Text strong>Email</Text>}>
              {isEmailUnchanged ? (
                <Text>{src?.preModification?.email}</Text>
              ) : (
                <CustomDescriptionItem
                  value={src?.preModification?.email}
                  editMode={false}
                  setValue={() => {}}
                  valueUpdated={src?.request?.updateRequest?.email}
                />
              )}
            </Descriptions.Item>

            <Descriptions.Item label={<Text strong>Phone Number</Text>}>
              {isPhoneNumberUnchanged ? (
                  <Text>{src?.preModification?.phoneNo}</Text>
              ) : (
                  <CustomDescriptionItem
                      value={src?.preModification?.phoneNo}
                      editMode={false}
                      setValue={() => {}}
                      valueUpdated={src?.request?.updateRequest?.phoneNumber ?? ""}
                  />
              )}
            </Descriptions.Item>

            <Descriptions.Item label={<Text strong>Residential Address</Text>}>
              {addressesAreEqual(
                  src?.request?.updateRequest?.residentialAddress,
                  src?.preModification?.residentialAddress,
              ) ? (
                  <Text>{formatAddress(src?.preModification?.residentialAddress)}</Text>
              ) : (
                  <CustomDescriptionItem
                      value={formatAddress(src?.preModification?.residentialAddress)}
                      editMode={false}
                      setValue={() => {}}
                      valueUpdated={formatAddress(src?.request?.updateRequest?.residentialAddress)}
                      direction="vertical"
                  />
              )}
            </Descriptions.Item>
          </Descriptions>

          <Divider />

          <Descriptions
            layout="vertical"
            column={2}
            contentStyle={{ marginTop: "-16px", maxWidth: "fit-content" }}
            title="Business Information"
          >
            <Descriptions.Item label={<Text strong>First Name</Text>}>
              <Text>{loanRequestDetails?.businessOwnerDetails?.firstName}</Text>
            </Descriptions.Item>

            <Descriptions.Item label={<Text strong>Last Name</Text>}>
              <Text>{loanRequestDetails?.businessOwnerDetails?.lastName}</Text>
            </Descriptions.Item>

            <Descriptions.Item label={<Text strong>Business Name</Text>}>
              <Text>{loanRequestDetails?.businessDetails?.businessName}</Text>
            </Descriptions.Item>

            <Descriptions.Item label={<Text strong>Business Type</Text>}>
              <Text>{loanRequestDetails?.businessDetails?.businessType}</Text>
            </Descriptions.Item>
          </Descriptions>

          <Divider />

          <Descriptions title="Proof Document">
            {fileError ? (
              <Descriptions.Item>
                <Alert message="Failed to load document." type="error" />
              </Descriptions.Item>
            ) : fileUrl ? (
              fileUrl.includes("image/jpeg") ? (
                <Descriptions.Item>
                  <Image src={fileUrl} width={200} />
                </Descriptions.Item>
              ) : (
                <Descriptions.Item>
                  <a
                    href={fileUrl}
                    download="ProofDocument.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Text strong>Download Proof Document</Text>
                  </a>
                </Descriptions.Item>
              )
            ) : null}
          </Descriptions>
        </>
      )}
    </div>
  );
};

export default GuarantorUpdateViewer;
