import { Button, Card, Row, Spin, Tabs, Tag } from "antd";
import { useEffect } from "react";
import {
  convertStatusToHumanReadable,
  parseAmountToCurrencyValue,
} from "../../../../../utils/dataParser";
import { convertStatusTextToColor } from "../../../../../utils/workflow";
import {
  LoanProduct,
  LoanProductToDescriptionMap,
} from "../../../LoanContractsManagement/utils/types";
import { LoanRequestTabEnum } from "../../loanRequestSlice/loanRequest.types";
import useQueryLoanRequest from "../hooks/useQueryLoanRequest";
import { LoanOfferStatus } from "../utils/types";
import BusinessAddress from "./loanDetails/BusinessAddress";
import BusinessOwnerDetails from "./loanDetails/BusinessOwnerDetails";
import LoanOfferDetails from "./loanDetails/LoanOfferDetails";
import LoanParticipants from "./loanDetails/LoanParticipants";
import LoanStatusTracker from "./loanDetails/LoanStatusTracker";
import ResidentialAddress from "./loanDetails/ResidentialAddress";
import PermissionDenied from "./LoanOfferErrorDetails";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useLoanOfferDetails } from "../hooks/useLoanOfferDetails";
import { useLoanApprovalDetails } from "../hooks/useLoanApprovalDetails";
import GuarantorDetails from "./loanDetails/GuarantorDetails";
import Config from "../../../../../config";
import { Feature } from "../../../../../config/feature-config";

type LoanTabDetailsProps = {
  key: string;
  label: string;
  children: any;
  getDetails?: (loanProduct: string, offerReference: string) => void;
}[];

export const detailStatusConfig: any = {
  [LoanRequestTabEnum.AWAITING_SUBMISSION]: {
    hideAmountDetails: true,
    hideLoanOfferDetails: true,
  },
};
type LoanRequestDetailsProps = {
  loanDetails: any;
  status: string;
  loanProduct: string;
  loanRequestReference: string | null;
  loanRequestStatusTab: string;
};
const LoanRequestsDetails = ({
  loanDetails,
  loanProduct,
  loanRequestReference,
  loanRequestStatusTab,
}: LoanRequestDetailsProps) => {
  const { loading, loanOfferDetails, getLoanOfferDetail, error } =
    useLoanOfferDetails();
  const { getLoanApprovalDetails, loanRequestApproval } =
    useLoanApprovalDetails({ loanReference: loanRequestReference });
  const navigate = useNavigate();
  const {
    error: loanDetailsError,
    loading: loanDetailsLoading,
    permissionError,
    getLoanDetails,
    loanRequestDetails,
  } = useQueryLoanRequest({
    loanReference: loanRequestReference,
  });
  const showAmountDetails =
    !detailStatusConfig[loanRequestStatusTab]?.hideAmountDetails;
  const displayApprovalStatus =
    loanRequestStatusTab === LoanRequestTabEnum.APPROVED ||
    loanRequestStatusTab === LoanRequestTabEnum.PENDING_APPROVAL ||
    loanRequestStatusTab === LoanRequestTabEnum.REJECTED;

  useEffect(() => {
    if (loanRequestReference) {
      getLoanDetails();
      if (displayApprovalStatus) {
        getLoanApprovalDetails();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let defaultTabItems: LoanTabDetailsProps = [
    {
      key: "1",
      label: "Business Owner Details",
      children: (
        <BusinessOwnerDetails
          details={loanRequestDetails?.businessOwnerDetails}
        />
      ),
    },
    {
      key: "2",
      label: "Business Address",
      children: (
        <BusinessAddress details={loanRequestDetails?.businessAddress} />
      ),
    },
    {
      key: "3",
      label: "Residential Address",
      children: (
        <ResidentialAddress details={loanRequestDetails?.residentialAddress} />
      ),
    },
    {
      key: "4",
      label: "Loan Offer details",
      getDetails: getLoanOfferDetail,
      children: (
        <LoanOfferDetails
          loanDetails={loanOfferDetails}
          loading={loading}
          error={error}
        />
      ),
    },
    {
      key: "5",
      label: "Loan Managers",
      children: (
        <LoanParticipants
          details={loanRequestDetails}
          loanRequestReference={loanRequestReference}
        />
      ),
    },
    {
      key: "6",
      label: "Guarantors",
      children: (
          <GuarantorDetails
              details={loanRequestDetails?.guarantorDetails}
              loanRequestReference={loanRequestReference}
              loanRequestStatusTab={loanRequestStatusTab}
          />
      ),
    },
  ];

  if (Config.excluded_features.includes(Feature.GUARANTOR_INFORMATION)) {
    defaultTabItems = defaultTabItems.filter((item) => item.key !== "6");
  }

  const tabItems = defaultTabItems.filter((item) => {
    const shouldShowLoanOfferDetails =
      !detailStatusConfig[loanRequestStatusTab]?.hideLoanOfferDetails;
    if (item.key === "4") {
      if (!shouldShowLoanOfferDetails || !loanDetails?.offerReference) {
        return false;
      }
      return true;
    }
    return true;
  });
  const goBack = () => {
    navigate(-1);
  };
  const handleTabChange = (tabKey: string) => {
    const tab = tabItems.find((item) => item?.key === tabKey);
    if (tab?.getDetails) {
      tab.getDetails(loanProduct, loanDetails.offerReference || "");
    }
  };
  const handleRetry = () => {
    getLoanDetails();
  };
  if (permissionError || loanDetailsError) {
    return (
      <PermissionDenied
        isPermissionError={false}
        errorMessage="Loan Request Details not found."
        backProps={{
          text: "Go Back",
          show: true,
        }}
        retryProps={{
          text: "Retry",
          onRetry: handleRetry,
        }}
      />
    );
  }

  if (loanDetailsLoading) {
    return (
      <div className="flex items-center justify-center">
        <Spin tip="Loading Details..." />
      </div>
    );
  }

  return (
    <>
      <div className="">
        <Button className="bg-white rounded-lg mb-2" onClick={goBack}>
          <ArrowLeftOutlined className="text-[#0190FE] mr-1" />
          Back
        </Button>
      </div>
      <div className="mb-4 bg-transparent w-full flex flex-col sm:flex-row">
        <div className="w-1/2 border-[#E6E7EB] p-3 bg-white border-[1px] border-solid rounded-lg inset-2">
          <div className="w-full flex">
            <div className="flex flex-col justify-start">
              <div className="text-sm font-normal ">Business Name:</div>
              <div className="text-lg text-black font-semibold">
                {loanDetails?.businessName}
              </div>
            </div>
          </div>
          <div className="mt-2">
            <>
              <div className="mt-2">
                Request Status:{" "}
                <Tag
                  className="ml-2"
                  color={convertStatusTextToColor(
                    loanRequestDetails?.requestStatus || "",
                  )}
                >
                  {convertStatusToHumanReadable(
                    loanRequestDetails?.requestStatus,
                  ) || ""}
                </Tag>
              </div>
              {loanRequestDetails?.requestStatus ===
                LoanOfferStatus.ACCEPTED && (
                <div className="mt-2">
                  Current Approval Status:{" "}
                  <Tag
                    className="ml-2"
                    color={convertStatusTextToColor(
                      loanRequestDetails?.approvalStatusDetails
                        ?.currentApprovalStatus || "",
                    )}
                  >
                    {convertStatusToHumanReadable(
                      loanRequestDetails?.approvalStatusDetails
                        ?.currentApprovalStatus,
                    ) || ""}
                  </Tag>
                </div>
              )}
            </>
          </div>
        </div>
        {showAmountDetails && (
          <>
            <div className="ml-4 w-1/2 border-[#E6E7EB] p-3 bg-white border-[1px] border-solid rounded-lg inset-2">
              <div className="mt-2 text-black">Loan Request Amount</div>
              <div className="mt-2 text-2xl font-semibold">
                {loanDetailsLoading ? (
                  <Spin />
                ) : loanRequestDetails?.amountRequested ? (
                  parseAmountToCurrencyValue(
                    loanRequestDetails?.amountRequested,
                    "₦",
                  )
                ) : (
                  "UNAVAILABLE"
                )}
              </div>
              <Tag
                className="mt-2 text-[#0361F0] rounded-md uppercase font-semibold"
                color="#E6EEFD"
              >
                {LoanProductToDescriptionMap[loanProduct as LoanProduct] ||
                  loanProduct}
              </Tag>
            </div>
          </>
        )}
      </div>
      {displayApprovalStatus && (
        <div className="mb-4">
          <LoanStatusTracker
            details={loanRequestApproval}
            isLoadingDetails={loanDetailsLoading}
          />
        </div>
      )}
      <Card>
        <Row style={{ marginTop: 40 }}>
          <Tabs
            defaultActiveKey="1"
            size="large"
            tabPosition="left"
            style={{ width: "100%" }}
            items={tabItems}
            onChange={handleTabChange}
          />
        </Row>
      </Card>
    </>
  );
};
export default LoanRequestsDetails;
