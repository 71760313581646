import { useState } from "react";
import { UploadFile } from "antd/es/upload/interface";
import { upload_file_service } from "../../Utils/FilesUtils/fileApi";
import { showError } from "../../../../utils/notifications";

export const useImageUpload = () => {
  const [uploading, setUploading] = useState<boolean>(false);

  const uploadImages = async (fileList: UploadFile<any>[]) => {
    setUploading(true);
    const imageReferences: string[] = [];
    if (fileList && fileList.length > 0) {
      for (const file of fileList) {
        const formData = new FormData();
        if (file?.originFileObj) {
          formData.append("file", file.originFileObj);
        } else {
          formData.append("file", file as unknown as Blob);
        }
        try {
          const response = await upload_file_service(formData);
          const imageReference = response.id; // Adjust according to your API response
          imageReferences.push(imageReference);
        } catch (error) {
          showError(error);
          setUploading(false);
          throw error; // Rethrow to handle in the component
        }
      }
    }
    setUploading(false);
    return imageReferences;
  };

  return { uploading, uploadImages };
};
