import {
  ILoanEligibilityResponse,
  WCLConversionEligibility,
} from "../modules/Dashboard/LoanRequestsManagement/AllLoanRequests/utils/types";

export const backendServiceMock = (itm: any, delay?: number) =>
  new Promise((resolve: (value: any) => void) => {
    setTimeout(() => {
      resolve(itm);
    }, delay || 1000);
  });
export const mockUsersData = [
  {
    name: "kkk",
    email: "tetet@gmail.com",
    userName: "comeflex",
    activation: "ACTIVE",
    status: "ENABLED",
  },
  {
    name: "kkk",
    email: "tetet@gmail.com",
    userName: "comeflex",
    activation: "INACTIVE",
    status: "DISABLED",
  },
  {
    name: "kkk",
    email: "tetet@gmail.com",
    userName: "comeflex",
    activation: "ACTIVE",
    status: "ENABLED",
  },
  {
    name: "kkk",
    email: "tetet@gmail.com",
    userName: "comeflex",
    activation: "INACTIVE",
    status: "DISABLED",
  },
  {
    name: "kkk",
    email: "tetet@gmail.com",
    userName: "comeflex",
    activation: "ACTIVE",
    status: "ENABLED",
  },
  {
    name: "kkk",
    email: "tetet@gmail.com",
    userName: "comeflex",
    activation: "INACTIVE",
    status: "DISABLED",
  },
];
export const mockLoanRequestDetails = {
  amountRequested: "100000",
  requestStatus: "Pending",
  approvalStatusDetails: {
    currentApprovalStatus: "In Review",
    previousApprovalStatus: "Submitted",
    previousApprovalStatusPassed: true,
    rejectedReason: "",
  },
  businessOwnerDetails: {
    profileImage:
      "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png",
    firstName: "Chinedu",
    lastName: "Adebayo",
    middleName: "Ifeanyi",
    phoneNumber: "08012345678",
    email: "chineduadebayo@example.com",
  },
  residentialAddress: {
    addressLineOne: "No. 5 Opebi Road",
    addressLineTwo: "Flat 2B",
    nearestLandmark: "Ikeja City Mall",
    nearestBusstop: "Allen Avenue",
    lga: "NGA-LA-010",
    city: "Lagos",
  },
  businessAddress: {
    businessName: "Adebayo Enterprises",
    addressLineOne: "12 Awolowo Way",
    addressLineTwo: "Suite 105",
    nearestLandmark: "Computer Village",
    nearestBusstop: "Medical Road",
    lga: "NGA-LA-008",
    city: "Lagos",
  },
  mpManagers: {
    brm: { code: "BRM100", name: "Oluchi Onwuka" },
    sc: { code: "SC200", name: "Tayo Oyetunde" },
    rm: { code: "RM300", name: "Emeka Nwosu" },
  },
  loanManagers: {
    firstFco: { code: "FCO101", name: "Kemi Adeola" },
    secondFco: null,
    pm: { code: "PM201", name: "Adaora Chukwuma" },
    rm: { code: "RM301", name: "Sani Ahmed" },
  },
};
export const mockLoanEligibilityDetails: ILoanEligibilityResponse = {
  businessOwnerName: "John Doe",
  businessOwnerCode: "xyz123",
  hasAccessToLoans: false, // true | false
  hasPendingLoanAccessRequest: true, // true | false
};
export const mockApprovalRequestStatus = {
  lastApprovalRequestStatus: "REJECTED", //"null|APPROVED|REJECTED|PENDING"
  comment: "Mock approval rejected status", // string | null
};
export const mockWCLConversionEligibility: WCLConversionEligibility = {
  offerConversionEligibility: "CONVERTIBLE",
  hasPriorRejectedRequest: false,
  rejectionReason: "",
};

export const mockLoanManagers = {
  fcos: [
    {
      code: "FCO-001",
      name: "John Smith",
    },
    {
      code: "FCO-002",
      name: "Jane Doe",
    },
  ],
  pm: {
    code: "PM-001",
    name: "Alice Johnson",
  },
  rm: {
    code: "RM-001",
    name: "Bob Brown",
  },
};

export const mockLoanRequestApprovalDetails = {
  loanRequestStatus: "ACCEPTED",
  loanAgreementAndFieldVerificationStatus: "APPROVED",
  loanAmount: 9180000.0,
  interestRate: 0.41,
  loanTenure: 20,
  loanTenureUnit: "Months",
  dateRequested: "2024-07-09",
  dateOfferedAccepted: "2024-07-09 13:19:58.0",
  managementFee: 91800.0,
  loanAgreements: [
    {
      firstName: "Johnny",
      lastName: "Bravo",
      middleName: null,
      type: "BUSINESS_OWNER",
      status: "APPROVED",
    },
    {
      firstName: "Debbie",
      lastName: "Stjohn",
      middleName: "A",
      type: "GUARANTOR",
      status: "APPROVED",
    },
    {
      firstName: "Amy",
      lastName: "Jonas",
      middleName: "L",
      type: "GUARANTOR",
      status: "APPROVED",
    },
  ],
  fieldVerifications: [
    {
      type: "BUSINESS_OWNER",
      status: "APPROVED",
    },
    {
      type: "BUSINESS",
      status: "APPROVED",
    },
    {
      type: "GUARANTOR",
      status: "APPROVED",
    },
    {
      type: "GUARANTOR",
      status: "APPROVED",
    },
  ],
};
