import React, { useCallback } from "react";
import {
  LoanProduct,
  loanProductToTypeMap,
} from "../../../LoanContractsManagement/utils/types";
import {
  get_loan_offer_audit_log_service,
  get_loan_offer_details_service,
} from "../API";
import { useLoadingAndErrorState } from "./useLoadingAndErrorState";
import { showMessage } from "../../../../../utils/notifications";
import { parseError } from "../../../../../utils/server";

export const useLoanOfferDetails = () => {
  const {
    loading,
    setLoading,
    error,
    setError,
    setLoadingError,
    setPermissionError,
  } = useLoadingAndErrorState();
  const [loanOfferDetails, setLoanOffer] = React.useState<any | null>(null);

  const getLoanOfferDetail = React.useCallback(
    async (
      loanProduct: string,
      loanReference: string,
      isApproved = false,
      workflowReference = "",
    ) => {
      try {
        setLoading(true);
        setError("");
        const loanProductType =
          loanProductToTypeMap[loanProduct as LoanProduct];
        const offerDetailsService = isApproved
          ? get_loan_offer_audit_log_service
          : get_loan_offer_details_service;
        const data = await offerDetailsService(
          loanProductType,
          loanReference,
          workflowReference,
        );
        if (isApproved) {
          setLoanOffer({
            ...JSON.parse(data?.preExecution),
            modificationRequest: JSON.parse(data?.modificationRequest),
            loanProduct,
            updateRequestStatus: data?.updateRequestStatus,
          });
          setLoading(false);
          return;
        }
        const loanProductKey = data.loanProduct;
        const offerDetails = data.offerResponse[loanProductKey];
        setLoanOffer({ ...offerDetails, loanProduct });
        setLoading(false);
      } catch (error: any) {
        if (error.response && error.response.status === 403) {
          setPermissionError(true);
          return; //exit early
        }
        showMessage("Loan Requests", parseError(error), "error");
        setError(parseError(error));
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return { getLoanOfferDetail, loading, error, loanOfferDetails };
};
