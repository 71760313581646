import React from "react";
import {showServerError} from "../../../../../utils/notifications";
import {
  extractPaginationData,
  initPagination,
  Pagination,
} from "../../../../../utils/pagination";
import { parseError } from "../../../../../utils/server";
import {
  api_fetch_silent_verification_requests,
} from "../../../LoanContractsManagement/API";

export interface SilentVerificationRequestsQueryProps {
  payload: {
    pagination?: Pagination;
  };
}

const useQuerySilentVerificationRequests = (
  props: SilentVerificationRequestsQueryProps,
) => {
  const {
    payload: { pagination },
  } = props;

  const [silentVerificationRequests, setSilentVerificationRequests] =
    React.useState<any[]>([]);
  const [
    silentVerificationRequestsPagination,
    setSilentVerificationRequestsPagination,
  ] = React.useState<Pagination>(initPagination);
  const [loading, setLoading] = React.useState(false);

  const getSilentVerificationRequests = React.useCallback(
    async (pagination?: Pagination,      searchParams?: Record<string, any>,) => {
      try {
        setLoading(true);
        const response = await api_fetch_silent_verification_requests(
          pagination,
            searchParams
        );
        const paginationData = extractPaginationData(response);
        setSilentVerificationRequests(response?.content);
        setSilentVerificationRequestsPagination(paginationData);
      } catch (error: any) {
        showServerError(parseError(error));
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  React.useEffect(() => {
    getSilentVerificationRequests(pagination);
  }, []);

  return {
    silentVerificationRequests,
    silentVerificationRequestsPagination,
    loading,
    getSilentVerificationRequests,
  };
};

export default useQuerySilentVerificationRequests;
