export const DashboardBasePath = "/dashboard";

export const DashboardRoutePaths = {
  home: {
    base: DashboardBasePath,
    relativePath: "/",
    path: `${DashboardBasePath}`,
  },
  brms: {
    base: DashboardBasePath,
    relativePath: "/business-relationship-managers",
    path: `${DashboardBasePath}/business-relationship-managers`,
  },
  brmdetails: {
    base: DashboardBasePath,
    relativePath: "/business-relationship-manager-details/:id",
    path: `${DashboardBasePath}/business-relationship-manager-details/:id`,
  },
  wclWorkflowPendingApprovals: {
    base: DashboardBasePath,
    relativePath: "/wcl/workflow/pending-approvals",
    path: `${DashboardBasePath}/wcl/workflow/pending-approvals`,
  },
  wclBackofficeWorkflowPendingApprovals: {
    base: DashboardBasePath,
    relativePath: "/wcl-back-office-workflows/pending",
    path: `${DashboardBasePath}/wcl-back-office-workflows/pending`,
  },
  wclCompletedWorkflow: {
    base: DashboardBasePath,
    relativePath: "/wcl/completed-workflow",
    path: `${DashboardBasePath}/wcl/completed-workflow`,
  },
  portfolioManagementWorkflowPendingApprovals: {
    base: DashboardBasePath,
    relativePath: "/portfolio-mgt/workflow/pending-approvals",
    path: `${DashboardBasePath}/portfolio-mgt/workflow/pending-approvals`,
  },
  portfolioManagementCompletedWorkflow: {
    base: DashboardBasePath,
    relativePath: "/portfolio-mgt/wcl/completed-workflow",
    path: `${DashboardBasePath}/portfolio-mgt/wcl/completed-workflow`,
  },
  portfolioManagementSettings: {
    base: DashboardBasePath,
    relativePath: "/portfolio-management/settings",
    path: `${DashboardBasePath}/portfolio-management/settings`,
  },
  viewPortfolioManagements: {
    base: DashboardBasePath,
    relativePath: "/portfolio-management/views-all",
    path: `${DashboardBasePath}/portfolio-management/views-all`,
  },
  viewProvisioning: {
    base: DashboardBasePath,
    relativePath: "/provisioning-template/views-all",
    path: `${DashboardBasePath}/provisioning-template/views-all`,
  },
  wclLgaActivation: {
    base: DashboardBasePath,
    relativePath: "/wcl/lga-activation",
    path: `${DashboardBasePath}/wcl/lga-activation`,
  },
  loanPrograms: {
    base: DashboardBasePath,
    relativePath: "/loan-programs/views-all",
    path: `${DashboardBasePath}/loan-programs/views-all`,
  },
  createLoanProgram: {
    base: DashboardBasePath,
    relativePath: "/loan-programs/create",
    path: `${DashboardBasePath}/loan-programs/create`,
  },
  editLoanProgram: {
    base: DashboardBasePath,
    relativePath: "/loan-programs/edit/:id",
    path: `${DashboardBasePath}/loan-programs/edit/:id`,
  },
  viewFcosManagers: {
    base: DashboardBasePath,
    relativePath: "/fco-management/views",
    path: `${DashboardBasePath}/fco-management/views`,
  },
  viewFcosManagerDetails: {
    base: DashboardBasePath,
    relativePath: "/fco-management/details/:id",
    path: `${DashboardBasePath}/fco-management/details/:id`,
  },
  createPortfolioLevel: {
    base: DashboardBasePath,
    relativePath: "/portfolio-management/create-portfolio-path",
    path: `${DashboardBasePath}/portfolio-management/create-portfolio-path`,
  },
  viewStateCoordinators: {
    base: DashboardBasePath,
    relativePath: "/state-coordinator-mgt/view-all",
    path: `${DashboardBasePath}/state-coordinator-mgt/view-all`,
  },
  viewAllUsers: {
    base: DashboardBasePath,
    relativePath: "/users-table/view-all",
    path: `${DashboardBasePath}/users-table/view-all`,
  },
  viewUserDetails: {
    base: DashboardBasePath,
    relativePath: "/users-details/details:id",
    path: `${DashboardBasePath}/users-details/details:id`,
  },
  viewLoanRequests: {
    base: DashboardBasePath,
    relativePath: "/loan-requests-mgt/view-all",
    path: `${DashboardBasePath}/loan-requests-mgt/view-all`,
  },
  viewAllLoanRequests: {
    base: DashboardBasePath,
    relativePath: "/all-loan-requests-mgt/view-all",
    path: `${DashboardBasePath}/all-loan-requests-mgt/view-all`,
  },
  viewAllLoanRequestDetails: {
    base: DashboardBasePath,
    relativePath: "/all-loan-requests-mgt/details/:id",
    path: `${DashboardBasePath}/all-loan-requests-mgt/details/:id`,
  },
  enableLoan: {
    base: DashboardBasePath,
    relativePath: "/loan/enable",
    path: `${DashboardBasePath}/loan/enable`,
  },
  viewBackofficeCompletedWorkflows: {
    base: DashboardBasePath,
    relativePath: "/back-office-workflows/completed",
    path: `${DashboardBasePath}/back-office-workflows/completed`,
  },
  viewLoanRequestDetails: {
    base: DashboardBasePath,
    relativePath: "/loan-requests-mgt/details/:id",
    path: `${DashboardBasePath}/loan-requests-mgt/details/:id`,
  },
  stateCoordinatorDetails: {
    base: DashboardBasePath,
    relativePath: "/state-coordinator-mgt/state-coordinators/:id",
    path: `${DashboardBasePath}/state-coordinator-mgt/state-coordinators/:id`,
  },
  portfolioManagerDetails: {
    base: DashboardBasePath,
    relativePath: "/portfolio-management/portfolio-managers/:id",
    path: `${DashboardBasePath}/portfolio-management/portfolio-managers/:id`,
  },
  loanProgramDetails: {
    base: DashboardBasePath,
    relativePath: "/loan-program/details/:id",
    path: `${DashboardBasePath}/loan-program/details/:id`,
  },
  WCLRegionalManagers: {
    base: DashboardBasePath,
    relativePath: "/regional-managers",
    path: `${DashboardBasePath}/regional-managers`,
  },
  WCLRegionalManagersDetails: {
    base: DashboardBasePath,
    relativePath: "/regional-managers/:id",
    path: `${DashboardBasePath}/regional-managers/:id`,
  },
  loanContracts: {
    base: DashboardBasePath,
    relativePath: "/loan-contracts",
    path: `${DashboardBasePath}/loan-contracts`,
  },
  loanContractsDetails: {
    base: DashboardBasePath,
    relativePath: "/loan-contracts/:id",
    path: `${DashboardBasePath}/loan-contracts/:id`,
  },
  silentVerification: {
    base: DashboardBasePath,
    relativePath: "/silent-verification",
    path: `${DashboardBasePath}/silent-verification`,
  },
  logout: {
    base: DashboardBasePath,
    relativePath: "/logout",
    path: `${DashboardBasePath}/logout`,
  },
};

export const getStaticPathString = (path: string) => path.split(":")[0];
